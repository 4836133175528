import s from '../../styles/pages/content.module.scss';
import React from 'react';
import {
  IAllTablesSchema,
  IAuthorsSchema,
  IPartnersSchema,
  IServicesSchema,
  ITagsSchema,
} from '../../settings/stores/settings';
import { observer } from 'mobx-react-lite';
import {
  ICurrencyProps,
  IStatusProps,
  SetValueProps,
} from '../../settings/utils/types';
import { Select } from 'antd';
import { useStore } from '../../settings/stores/use-store';
const { Option } = Select;

interface CustomSelectProps {
  dataTable:
    | IAllTablesSchema[]
    | IStatusProps[]
    | ITagsSchema[]
    | IPartnersSchema[]
    | IServicesSchema[]
    | ICurrencyProps[]
    | IAuthorsSchema[];
  defaultValue: string | number | string[] | number[] | (string | number)[];
  item: string;
  setValue: (value: SetValueProps) => void;
  multiple?: boolean;
}
const CustomSelect = ({
  dataTable,
  item,
  defaultValue,
  setValue,
  multiple = false,
}: CustomSelectProps) => {
  const {
    settingsStore: { t },
  } = useStore();
  return (
    <Select
      mode={multiple ? 'multiple' : undefined}
      size="large"
      className={s.formItem}
      defaultValue={defaultValue}
      onChange={(e) => setValue({ [item]: e })}
      allowClear
    >
      {dataTable?.map(
        ({ label, id, title, key, value }: any, index: number) => (
          <Option
            key={`${title}_${index}`}
            value={value || id}
            label={t(key as string) || label || title}
          >
            {t(key as string) || label || title}
          </Option>
        ),
      )}
    </Select>
  );
};

export default observer(CustomSelect);
