import { CustomButton } from '../ui/custom-button';
import { ActionTypes } from '../../settings/utils/types';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStore } from '../../settings/stores/use-store';
interface ActionCrudBlockProps {
  recordId: number;
}
const ActionCrudBlock = ({ recordId }: ActionCrudBlockProps) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    settingsStore: {
      t,
      setActionType,
      setActionId,
      setOpenModal,
      setActiveTable,
      getContentTables,
    },
  } = useStore();
  return (
    <div className="crudBlock">
      <CustomButton
        type="primary"
        size="small"
        onClick={() => {
          setActiveTable(recordId);
          navigate(`${pathname}/edit/${recordId}`);
        }}
        withIcon
        icon="EditOutlined"
        withTooltip
        tooltip={t('Edit')}
      />
      {getContentTables.includes(id as string) && (
        <CustomButton
          type="primary"
          size="small"
          onClick={() => {
            setActiveTable(recordId);
            navigate(`${pathname}/image/${recordId}`);
          }}
          withIcon
          icon="FileImageOutlined"
          withTooltip
          tooltip={t('Image')}
        />
      )}
      {getContentTables.includes(id as string) && (
        <CustomButton
          type="primary"
          size="small"
          onClick={() => {
            setActiveTable(recordId);
            navigate(`${pathname}/images/${recordId}`);
          }}
          withIcon
          icon="AppstoreOutlined"
          withTooltip
          tooltip={t('Images')}
        />
      )}
      <CustomButton
        danger
        type="primary"
        size="small"
        onClick={() => {
          setOpenModal(true);
          setActionType(ActionTypes.DELETE);
          setActionId(recordId);
        }}
        withIcon
        icon="DeleteOutlined"
        withTooltip
        tooltip={t('Delete')}
      />
    </div>
  );
};

export default observer(ActionCrudBlock);
