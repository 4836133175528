import { flow, Instance, types as t, cast } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { requests } from '../api';

const TablesStore = t
  .model('TablesStore', {
    rowsCount: t.maybeNull(t.number),
  })
  .views((self) => ({
    get getRowsCount() {
      return toJS(self.rowsCount);
    },
  }))
  .actions((self) => {
    const setRowsCount = (count: number) => {
      self.rowsCount = count;
    };

    // const fetchTables = flow(function* (token: string) {
    //   try {
    //     const json = yield requests.getTablesList(token);
    //     return { status: 200, data: json.data.tables };
    //   } catch (err) {
    //     console.error('err', err);
    //     return false;
    //   }
    // });

    return {
      setRowsCount,
      // fetchTables,
    };
  });

export type ITablesStore = Instance<typeof TablesStore>;

export default TablesStore;
