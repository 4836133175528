import s from './form.module.scss';
import { FormItem } from './form-item';
import { CustomButton } from '../ui/custom-button';
import { useEffect, useRef, useState } from 'react';
import {
  ActionTypes,
  FormProps,
  Methods,
  Token,
} from '../../settings/utils/types';
import { useStore } from '../../settings/stores/use-store';
import { mistakeUpdater } from '../../settings/utils/mistakeUpdater';
import { observer } from 'mobx-react-lite';
import { getCookie } from 'cookies-next';
import { useNavigate } from 'react-router-dom';

interface FormComponentProps {
  formItems: FormProps[];
  buttonText: string;
  action?: string;
  method?: Methods;
  useApi?: boolean;
  actionType?: ActionTypes;
}

const LoginForm = ({
  formItems,
  buttonText,
  useApi,
  actionType,
}: FormComponentProps) => {
  const {
    settingsStore: {
      getEmail,
      getPassword,
      setMistakeText,
      setMistake,
      t,
      getMistake,
      getNickName,
    },
    authStore: { registerUser, loginUser },
  } = useStore();
  const navigate = useNavigate();
  const [secret, setSecret] = useState<string | null>(null);
  const [sent, setSent] = useState<boolean>(false);

  const form = useRef<HTMLFormElement>();

  useEffect(() => {
    if (getCookie(Token.id)) {
      setSecret(getCookie(Token.id) as string);
    }
  }, []);

  useEffect(() => {
    mistakeUpdater(setMistake);
  }, [getMistake]);

  useEffect(() => {
    if (sent && useApi) {
      if (actionType === ActionTypes.REGISTER) {
        registerUser(
          getNickName as string,
          getEmail as string,
          getPassword as string,
          secret as string,
        ).then((res: any) => {
          if (res && res.status === 200) {
            navigate('/');
            window.location.reload();
          } else {
            setMistake(false);
            setMistakeText(t('something wrong'));
          }
        });
      } else if (actionType === ActionTypes.LOGIN) {
        setSent(false);

        loginUser(
          getEmail as string,
          getPassword as string,
          secret as string,
        ).then((res: any) => {
          if (res && res.status === 200) {
            navigate('/');
            window.location.reload();
          } else {
            setMistake(false);
            setMistakeText(t('something wrong'));
          }
        });
      }
    }
  }, [sent]);

  return (
    <form
      autoComplete="off"
      className={s.form}
      ref={(ref: HTMLFormElement) => (form.current = ref)}
    >
      {formItems.map(
        ({ name, value, setValue, widthIcon, icon, placeholder, type }) => (
          <FormItem
            key={name}
            name={name}
            value={value}
            setValue={setValue}
            widthIcon={widthIcon}
            icon={icon}
            placeholder={placeholder}
            type={type}
          />
        ),
      )}
      {secret && (
        <input name="secret" type="hidden" defaultValue={secret as string} />
      )}
      {getMistake && <div>{getMistake}</div>}
      <CustomButton
        className={s.formButton}
        text={buttonText}
        type="primary"
        onClick={() => {
          setSent(true);
        }}
      />
      <div className="separator" />
    </form>
  );
};

export default observer(LoginForm);
