import { Languages, LanguagesProp } from '../utils/types';

export const dictionary: {
  [key: string]: LanguagesProp;
} = {
  'Sign in page': { [Languages.RU]: 'Вход' },
  'Sign in': { [Languages.RU]: 'Войти' },
  'Sign up': { [Languages.RU]: 'Регистрация' },
  Password: { [Languages.RU]: 'Пароль' },
  Repair: { [Languages.RU]: 'Восстановить' },
  'Create an account': { [Languages.RU]: 'Создать аккаунт' },
  'Already have an account?': { [Languages.RU]: 'Уже есть аккаунт?' },
  'Repair?': { [Languages.RU]: 'Восстановить?' },
  'Repair an account': { [Languages.RU]: 'Восстановить аккаунт' },
  'Account repairing': { [Languages.RU]: 'Восстановление аккаунта' },
  Logout: { [Languages.RU]: 'Выйти' },
  articles: { [Languages.RU]: 'Манифесты' },
  users: { [Languages.RU]: 'Пользователи' },
  tables: { [Languages.RU]: 'таблицы' },
  Tables: { [Languages.RU]: 'Таблицы' },
  Home: { [Languages.RU]: 'Главная' },
  edit: { [Languages.RU]: 'Редактировать' },
  Edit: { [Languages.RU]: 'Редактировать' },
  Editing: { [Languages.RU]: 'Редактирование' },
  create: { [Languages.RU]: 'Создание' },
  Create: { [Languages.RU]: 'Создать' },
  Cancel: { [Languages.RU]: 'Отменить' },
  Save: { [Languages.RU]: 'Сохранить' },
  'Choose file': { [Languages.RU]: 'Выбрать файл' },
  Image: { [Languages.RU]: 'Изображение' },
  Images: { [Languages.RU]: 'Изображения' },
  Delete: { [Languages.RU]: 'Удалить' },
  Active: { [Languages.RU]: 'Активно' },
  Disabled: { [Languages.RU]: 'Неактивно' },
  tags: { [Languages.RU]: 'теги' },
  Tags: { [Languages.RU]: 'Теги' },
  authors: { [Languages.RU]: 'авторы' },
  Authors: { [Languages.RU]: 'Авторы' },
  categories: { [Languages.RU]: 'категории' },
  Categories: { [Languages.RU]: 'Категории' },
  analysis: { [Languages.RU]: 'анализы' },
  Analysis: { [Languages.RU]: 'Анализы' },
  settings: { [Languages.RU]: 'настройки' },
  Settings: { [Languages.RU]: 'Настройки' },
  groups: { [Languages.RU]: 'группы' },
  Groups: { [Languages.RU]: 'Группы' },
  dashboard: { [Languages.RU]: 'панель' },
  Dashboard: { [Languages.RU]: 'Панель' },
  Confirm: { [Languages.RU]: 'Подтвердить' },
  Pages: { [Languages.RU]: 'Страницы' },
  pages: { [Languages.RU]: 'Страницы' },
  museums: { [Languages.RU]: 'Музеи' },
  Museums: { [Languages.RU]: 'Музеи' },
  slides: { [Languages.RU]: 'Слайды' },
  Slides: { [Languages.RU]: 'Слайды' },
  People: { [Languages.RU]: 'Люди' },
  people: { [Languages.RU]: 'люди' },
  dictionary: { [Languages.RU]: 'словарь' },
  Dictionary: { [Languages.RU]: 'Словарь' },
  ManifestType: { [Languages.RU]: 'Направление' },
  manifestType: { [Languages.RU]: 'Направление' },
  city: { [Languages.RU]: 'Город' },
  news: { [Languages.RU]: 'Новости' },
  new: { [Languages.RU]: 'Новость' },
  expenses: { [Languages.RU]: 'расходы' },
  accesses: { [Languages.RU]: 'доступы' },
  offers: { [Languages.RU]: 'офферы' },
  partners: { [Languages.RU]: 'партнёры' },
  services: { [Languages.RU]: 'сервисы' },
  City: { [Languages.RU]: 'Город' },
  'Don`t have an account? Create to use it': {
    [Languages.RU]: 'Нет аккаунта? Создай чтобы управлять',
  },
};
