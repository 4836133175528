import s from './leads-charts.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../settings/stores/use-store';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts/highstock';
import dayjs from 'dayjs';

export const LeadsChart = () => {
  const {
    settingsStore: { getLeadsToChart },
  } = useStore();

  const options = {
    title: {
      text: 'Лиды',
      align: 'left',
    },
    subtitle: {
      text: 'Можно увеличивать',
      align: 'left',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Дата',
      },
      labels: {
        formatter: function (this: any) {
          return dayjs(this.value as any)
            .utc()
            .local()
            .format('DD.MM.YYYY') as any;
        } as any,
      },
      tickInterval: 86400000,
    },
    yAxis: {
      title: {
        text: 'Количество',
      },
      tickInterval: 1,
      // type: 'linear',
    },
    chart: {
      zoomType: 'x',
    },

    plotOptions: {
      series: {
        cumulative: true,
        pointStart: Date.UTC(2023, 0, 1),
        pointIntervalUnit: 'day',
      },
    },
    rangeSelector: {
      enabled: false,
    },

    series: [
      {
        name: 'Всего лидов',
        data: getLeadsToChart.chartLeads,
        color: '#FF0000',
      },
      {
        name: 'Активные лиды',
        data: getLeadsToChart.chartLeadsIsActive,
        color: '#1ea924',
      },
      {
        name: 'Фейковые лиды',
        data: getLeadsToChart.chartLeadsIsFake,
        color: '#000000',
      },
    ],
  };

  return (
    <div className={s.leadsChart} style={{ height: 500, width: '70%' }}>
      {getLeadsToChart && (
        <HighchartsReact
          constructorType={'stockChart'}
          highcharts={Highcharts}
          options={options}
        />
      )}
    </div>
  );
};

export default observer(LeadsChart);
