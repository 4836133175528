import { observer } from 'mobx-react-lite';
import { withRouter } from '../../settings/utils/withRouter';
import LoginBlock from '../../components/login-block';
import {
  ActionTypes,
  FormProps,
  FormTypeInput,
} from '../../settings/utils/types';
import { useStore } from '../../settings/stores/use-store';
import { ScheduleOutlined, UnlockOutlined } from '@ant-design/icons';

const Login = () => {
  const {
    settingsStore: { getEmail, setEmail, getPassword, setPassword },
  } = useStore();
  const formItems: FormProps[] = [
    {
      name: 'email',
      defaultValue: getEmail as string,
      setValue: setEmail,
      widthIcon: true,
      icon: <ScheduleOutlined rev="" />,
      placeholder: 'email',
      type: FormTypeInput.TEXT,
    },
    {
      name: 'password',
      defaultValue: getPassword as string,
      setValue: setPassword,
      widthIcon: true,
      icon: <UnlockOutlined rev="" />,
      placeholder: 'password',
      type: FormTypeInput.PASSWORD,
    },
  ];
  return <LoginBlock actionType={ActionTypes.LOGIN} formItems={formItems} />;
};

export default withRouter(observer(Login));
