import s from './signup.module.scss';
import { Copyright } from '../../copyright';
import { QrcodeOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

const Signup = ({ children }: any) => (
  <div className={s.loginScreen}>
    <div className={s.logoBlock}>
      <QrcodeOutlined rev="" size={60} />
    </div>
    <div className={s.signIn}>
      {children}
      <Copyright />
    </div>
  </div>
);

export default observer(Signup);
