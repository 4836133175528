import { useContext } from 'react';
import { storeContext } from './provider';
import { IStore } from './store';

export const useStore = (): IStore => {
  const store = useContext(storeContext);
  if (!store) {
    throw new Error(
      'No mobx store instance found! You probably forgot to add context provider.'
    );
  }
  return store;
};
