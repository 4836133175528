import { observer } from 'mobx-react-lite';

interface ContentImage {
  title: string;
  url: string;
}
const ContentImage = ({ title, url }: ContentImage) => (
  <img src={url} alt={title} />
);

export default observer(ContentImage);
