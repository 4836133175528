import s from './main.module.scss';
import Content from './content';
import { observer } from 'mobx-react-lite';
import Aside from './aside';

const Main = ({ children }: any) => {
  return (
    <div className={s.wrap}>
      <Aside />
      <Content>{children}</Content>
    </div>
  );
};

export default observer(Main);
