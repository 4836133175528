import { CustomButton } from '../ui/custom-button';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../settings/stores/use-store';

const TableHeaderBlock = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    settingsStore: { getActiveTable, t },
  } = useStore();
  return (
    <div className="tableHeader">
      <CustomButton
        type="primary"
        onClick={() => {
          getActiveTable &&
            navigate(
              `${pathname}/create/${(getActiveTable[0]?.count as number) + 1}`,
            );
        }}
        text={t('Create')}
      />
    </div>
  );
};

export default observer(TableHeaderBlock);
