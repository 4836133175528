import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/global.scss';
import 'antd/dist/reset.css';
import reportWebVitals from './reportWebVitals';

import store, { IStore } from '../src/settings/stores/store';
import StoreProvider from './settings/stores/provider';
import Router from './routing/own-router';

const hope: IStore = store.create({}, {}) as IStore;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StoreProvider store={hope}>
    <React.StrictMode>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </React.StrictMode>
  </StoreProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
