import s from './leads-table.module.scss';
import { observer } from 'mobx-react-lite';
import { Table, TableProps } from 'antd';
import { useStore } from '../../../settings/stores/use-store';

interface DataType {
  key: string;
  date: string;
  all: number;
  active: number;
  fake: number;
  waiting: number;
}

export const LeadsTable = () => {
  const {
    settingsStore: { getLeadsToChart },
  } = useStore();

  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (_, record) => (
        <span style={{ textAlign: 'center', display: 'inline-block' }}>
          {record.date}
        </span>
      ),
    },
    {
      title: 'Всего',
      dataIndex: 'all',
      key: 'all',
      render: (_, record) => (
        <span style={{ textAlign: 'center', display: 'inline-block' }}>
          {record.all}
        </span>
      ),
    },
    {
      title: 'Актив',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => (
        <span style={{ textAlign: 'center', display: 'inline-block' }}>
          {record.active}
        </span>
      ),
    },
    {
      title: 'Фейк',
      dataIndex: 'fake',
      key: 'fake',
      render: (_, record) => (
        <span style={{ textAlign: 'center', display: 'inline-block' }}>
          {record.fake}
        </span>
      ),
    },
    {
      title: 'Ждём',
      dataIndex: 'waiting',
      key: 'waiting',
      render: (_, record) => (
        <span style={{ textAlign: 'center' }}>
          {record.all - record.active - record.fake}
        </span>
      ),
    },
  ];
  return (
    <div className={s.leadsTable}>
      {getLeadsToChart && (
        <Table
          columns={columns}
          dataSource={getLeadsToChart.tableView}
          rowClassName={(record) =>
            record.all - record.active - record.fake > 0 ? 'danger' : 'success'
          }
        />
      )}
    </div>
  );
};

export default observer(LeadsTable);
