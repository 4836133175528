import { Input } from 'antd';
import cn from 'classnames';
import { ReactNode } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

interface InputTextProps {
  setValue?: (value: string) => void;
  value?: string;
  defaultValue?: string;
  className?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  minLength?: number;
  onKeyPress?: any;
  size?: SizeType;
  autoFocus?: boolean;
  widthIcon?: boolean;
  icon?: ReactNode;
}
export const InputText = ({
  size = 'large',
  setValue,
  value,
  defaultValue,
  className,
  name,
  maxLength,
  minLength,
  placeholder,
  onKeyPress,
  autoFocus = false,
  widthIcon = false,
  icon,
  type,
}: InputTextProps) => {
  return (
    <Input
      size={size}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      maxLength={maxLength}
      minLength={minLength}
      name={name}
      className={cn('input', className)}
      value={value}
      onChange={(e) => {
        setValue && setValue(e.target.value);
      }}
      prefix={widthIcon ? icon : undefined}
      autoComplete="off"
      type={type ? type : undefined}
    />
  );
};
