import { Routes, Route, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Error } from '../pages/error';
import { observer } from 'mobx-react-lite';
import { withRouter } from '../settings/utils/withRouter';
import { useStore } from '../settings/stores/use-store';
import { RoutesProps } from '../settings/utils/types';
import { scrollToTop } from '../settings/common/scroll-to-top';
import { routes } from './routes';
import Main from '../components/layout';
import Signup from '../components/layout/signup';

const Router = () => {
  const { pathname } = useLocation();

  const { authStore } = useStore();
  const { getIsAuth, getIsLoading } = authStore;

  useEffect(() => {
    scrollToTop();
    // if (key === 'default') { //TODO: тут стоит подумать, так как дефоль не всегда срабатывает
    //   navigate('/', { replace: true });
    // }
  }, [pathname]);

  console.log('getIsAuth', getIsAuth);
  console.log('getIsLoading', getIsLoading);

  return (
    <main>
      <Routes>
        {routes
          .filter((item) => (getIsAuth ? item.isAdmin : !item.isAdmin))
          .map(({ id, path, component }: RoutesProps) => (
            <Route
              key={id}
              path={path}
              element={
                (!getIsLoading && getIsAuth ? (
                  <Main>{component}</Main>
                ) : (
                  <Signup>{component}</Signup>
                )) as React.ReactNode
              }
            />
          ))}
        <Route element={<Error />} />
      </Routes>
    </main>
  );
};

export default withRouter(observer(Router));
