import s from './table-image.module.scss';
import { withRouter } from '../../settings/utils/withRouter';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../settings/stores/use-store';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ContentImage from '../../components/content-image';
import { useEffect, useRef, useState } from 'react';
import { CustomButton } from '../../components/ui/custom-button';
import { getRandomInt } from '../../settings/utils/get-random-id';
import { InputText } from '../../components/ui/input-text';
import cn from 'classnames';
import { Loader } from '../../components/loader';

interface GalleryProps {
  id: number;
  link: string;
  imageTitle: string;
}

const TableImages = () => {
  const { id, uid } = useParams();

  const {
    settingsStore: {
      getActiveTableItem,
      t,
      fetchTableContent,
      setActiveTable,
      setSent,
      getSent,
      setDelete,
      getDelete,
      sendImages,
      deleteImages,
      updateContent,
    },
    authStore: { getToken },
  } = useStore();

  useEffect(() => {
    if (!getActiveTableItem && uid) {
      fetchTableContent(getToken as string, id as string).then((res) => {
        if (res && res.status === 200) {
          setActiveTable(+uid as number);
        }
      });
    }
  }, [getActiveTableItem, uid]);

  const form = useRef<HTMLFormElement>();
  const fileInput = useRef<HTMLInputElement>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [updateImage, setUpdateImage] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [imageKey, setImageKey] = useState<number>();
  const [imageTitle, setImageTitle] = useState<string>();
  const [gallery, setGallery] = useState<GalleryProps[]>([]);
  const [newGalleryItem, setNewGalleryItem] = useState<boolean>(false);
  const [updateTitle, setUpdateTitle] = useState<boolean>(false);
  const [activeImage, setActiveImage] = useState<number>();
  const [save, setSave] = useState<boolean>(false);

  useEffect(() => {
    const tableGallery = getActiveTableItem?.gallery;
    if (tableGallery) {
      console.log(
        'JSON.parse(tableGallery as string)',
        JSON.parse(tableGallery as string),
      );
      setGallery(JSON.parse(tableGallery as string));
    }
  }, [getActiveTableItem, uid]);

  useEffect(() => {
    if (newGalleryItem) {
      const newItem = getRandomInt(100000);
      const idExist = gallery.find((item) => item.id === newItem);
      if (!idExist) {
        gallery.push({
          id: newItem,
          link: '',
          imageTitle: imageTitle as string,
        });
        setGallery(gallery);
        setActiveImage(newItem);
      }
      setNewGalleryItem(false);
    }
  }, [newGalleryItem]);

  useEffect(() => {
    if (updateTitle && id && uid) {
      let target = gallery.find(
        (item) => item.id === activeImage,
      ) as GalleryProps;
      target['imageTitle'] = imageTitle as string;
      const newGallery = gallery.filter((item) => item.id !== activeImage);

      newGallery.push(target);
      setSave(true);
      updateContent(getToken as string, id as string, +uid as number, {
        gallery: JSON.stringify(newGallery),
        status: 1,
      }).then(() => {
        setSave(false);
      });
      setUpdateTitle(false);
    }
  }, [updateTitle]);

  useEffect(() => {
    const doneAction = function done() {
      setCopied(false);
    };
    const setDone = setTimeout(doneAction, 3000);
    return () => clearTimeout(setDone);
  }, [copied]);

  useEffect(() => {
    if (getToken && getSent && fileInput?.current?.files) {
      sendImages(
        getToken,
        fileInput?.current?.files[0],
        imageUrl,
        id as string,
        uid as string,
        imageTitle as string,
        imageKey as number,
        JSON.stringify(gallery) as string,
      ).then((res) => {
        if (res) {
          window.location.reload();
        }
      });
      setSent(false);
    }
  }, [getSent]);
  useEffect(() => {
    if (getToken && getDelete) {
      deleteImages(
        getToken,
        id as string,
        uid as string,
        gallery.find((item) => item.id === activeImage)?.link as string,
        JSON.stringify(
          gallery.filter((item) => item.id !== activeImage),
        ) as string,
      ).then((res) => {
        if (res) {
          window.location.reload();
        }
      });
      setSent(false);
    }
  }, [getDelete]);

  useEffect(() => {
    if (
      updateImage &&
      fileInput &&
      fileInput?.current &&
      fileInput?.current?.files &&
      fileInput?.current?.files.length > 0
    ) {
      setImageUrl(URL.createObjectURL(fileInput?.current?.files[0]));
      setUpdateImage(false);
    }
  }, [updateImage, fileInput, imageUrl, uid]);

  return (
    <div className="content">
      <a href={`/tables/${id}`}>
        <ArrowLeftOutlined rev="" /> Вернуться к таблицам
      </a>
      <div className={s.imagePage}>
        <h2>{`${getActiveTableItem?.title && getActiveTableItem?.title} - ${t(
          'Images',
        )}`}</h2>

        {gallery.map((item) => {
          return (
            <div className={s.imageItemBlock} key={item.id}>
              <div className={s.imageBlock}>
                <div className={s.imageWrap}>
                  {item && (
                    <ContentImage
                      title={id as string}
                      url={`${process.env.REACT_APP_BACKEND_HOST_ORIGINAL}/${item.link}`}
                    />
                  )}
                </div>
                <div className={s.imageWrap}>
                  {imageUrl && activeImage === item.id && (
                    <>
                      <h2>Новое изображение</h2>
                      <ContentImage title="New Image" url={imageUrl} />
                    </>
                  )}
                </div>
              </div>
              <form
                className={s.form}
                ref={(ref: HTMLFormElement) => (form.current = ref)}
              >
                <input
                  type="hidden"
                  name="secret"
                  defaultValue={getToken as string}
                />
                <input
                  ref={(input: HTMLInputElement) => (fileInput.current = input)}
                  name="file"
                  hidden
                  type="file"
                  onChange={(e) => {
                    setUpdateImage(true);
                  }}
                />
                <input
                  type="hidden"
                  name="imageKey"
                  defaultValue={item.id.toString() as string}
                  onLoad={() => setImageKey(item.id)}
                />
                <div className={s.formActionsBlock}>
                  <CustomButton
                    className={s.chooseButton}
                    icon="PlusOutlined"
                    fill="#FFFFFF"
                    withIcon
                    type="default"
                    onClick={() => {
                      fileInput.current?.click();
                      setActiveImage(item.id);
                    }}
                    text={t('Choose file')}
                  />
                  {fileInput.current && imageUrl && (
                    <CustomButton
                      type="primary"
                      danger
                      onClick={() => {
                        setSent(false);
                        setImageUrl('');
                        setUpdateImage(false);
                      }}
                      text={t('Cancel')}
                    />
                  )}
                  <CustomButton
                    type="primary"
                    onClick={() => {
                      setImageKey(item.id);
                      setSent(true);
                    }}
                    text={t('Save')}
                  />
                  <CustomButton
                    danger
                    type="primary"
                    onClick={() => {
                      setActiveImage(item.id);
                      setDelete(true);
                    }}
                    text={t('Delete')}
                  />
                </div>
              </form>
              <div className={cn(s.formInputItem, s.comments)}>
                <label className={s.labelBlock} htmlFor="imageTitle">
                  <span className={s.labelText}>Комментарий к картинке</span>

                  <InputText
                    className={s.commentBlockInput}
                    name="imageTitle"
                    defaultValue={item.imageTitle}
                    setValue={(e) => {
                      setActiveImage(item.id);
                      setImageTitle(e);
                    }}
                  />
                  <CustomButton
                    className={s.commentsButton}
                    type="primary"
                    size="large"
                    onClick={() => {
                      setUpdateTitle(true);
                    }}
                    text="Сохранить"
                    withIcon
                    icon="SaveOutlined"
                    withTooltip
                    tooltip={t('Кликни чтобы сохранить')}
                  />
                </label>
              </div>
              {item && (
                <div className={s.copyPath}>
                  <p>Ссылка для копирования картинки</p>{' '}
                  <div>
                    {`<div class="contentImage"><img src="${
                      process.env.REACT_APP_BACKEND_HOST_ORIGINAL
                    }/${item.link}" alt="${
                      item.imageTitle ? item.imageTitle : item.id
                    }" />${
                      item.imageTitle ? `<p>${item.imageTitle}</p>` : ''
                    }</div>`}
                    <CustomButton
                      type="primary"
                      size="small"
                      onClick={() => {
                        setCopied(true);
                        navigator.clipboard.writeText(
                          `<div class="contentImage"><img src="${
                            process.env.REACT_APP_BACKEND_HOST_ORIGINAL
                          }/${item.link}"  alt="${
                            item.imageTitle ? item.imageTitle : item.id
                          }" />${
                            item.imageTitle ? `<p>${item.imageTitle}</p>` : ''
                          }</div>`,
                        );
                      }}
                      withIcon
                      icon="CopyOutlined"
                      withTooltip
                      tooltip={t('Кликни чтобы скопировать')}
                    />
                  </div>
                </div>
              )}
              {copied && <p className={s.copied}>Скопировано!</p>}
            </div>
          );
        })}

        <div className={s.setNewImage}>
          <CustomButton
            withIcon
            type="primary"
            onClick={() => {
              setNewGalleryItem(true);
            }}
            text="Добавить ещё"
            icon="PlusSquareOutlined"
          />
        </div>
      </div>
      {save && <Loader />}
    </div>
  );
};

export default withRouter(observer(TableImages));
