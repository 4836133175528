import s from '../../styles/pages/signin.module.scss';
import { CustomButton } from '../ui/custom-button';
import { useStore } from '../../settings/stores/use-store';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';
interface HaveAnAccountProps {
  title: string;
  buttonText: string;
  link: string;
  repair?: boolean;
}
export const HaveAnAccount = ({
  title,
  buttonText,
  link,
  repair = false,
}: HaveAnAccountProps) => {
  const {
    settingsStore: { t },
  } = useStore();
  const navigate = useNavigate();
  return (
    <div className={s.haveAnAccount}>
      <p className={s.text}>{title}</p>
      <CustomButton
        className={s.signUpButton}
        text={buttonText}
        type="link"
        onClick={() => {
          navigate(link);
        }}
      />
      {repair && (
        <CustomButton
          className={s.repairButton}
          text={t('Repair?')}
          type="link"
          onClick={() => {
            navigate('/repair');
          }}
        />
      )}
    </div>
  );
};
