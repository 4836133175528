import { RoutesProps } from '../settings/utils/types';
import Dashboard from '../pages/dashboard';
import Login from '../pages/login';
import Tables from '../pages/tables';
import Table from '../pages/tabel';
import TableImage from '../pages/tabel-image';
import TableImages from '../pages/tabel-images';
import TableAction from '../pages/tabel-action';
import Register from '../pages/register';

export const routes: RoutesProps[] = [
  {
    id: 1,
    path: '/',
    component: <Dashboard />,
    mainRoutes: true,
    isActive: true,
    isAdmin: true,
  },
  {
    id: 2,
    path: '/',
    component: <Login />,
    mainRoutes: true,
    isActive: true,
    isAdmin: false,
  },
  {
    id: 3,
    path: '/tables/:id',
    component: <Tables />,
    mainRoutes: true,
    isActive: true,
    isAdmin: true,
  },
  {
    id: 4,
    path: '/tables/:id/:uid',
    component: <Table />,
    mainRoutes: true,
    isActive: true,
    isAdmin: true,
  },
  {
    id: 5,
    path: '/tables/:id/image/:uid',
    component: <TableImage />,
    mainRoutes: true,
    isActive: true,
    isAdmin: true,
  },
  {
    id: 6,
    path: '/tables/:id/images/:uid',
    component: <TableImages />,
    mainRoutes: true,
    isActive: true,
    isAdmin: true,
  },
  {
    id: 7,
    path: '/tables/:id/:action/:uid',
    component: <TableAction />,
    mainRoutes: true,
    isActive: true,
    isAdmin: true,
  },
  {
    id: 8,
    path: '/register',
    component: <Register />,
    mainRoutes: true,
    isActive: true,
    isAdmin: false,
  },
];
