import { Instance, types as t } from 'mobx-state-tree';
import { toJS } from 'mobx';

const DashboardStore = t
  .model('DashboardStore', {
    users: t.maybeNull(t.number),
    articles: t.maybeNull(t.number),
    isLoading: t.boolean,
  })
  .views((self) => ({
    get getUsersCount() {
      return toJS(self.users);
    },
    get getArticlesCount() {
      return toJS(self.articles);
    },
    get getIsLoading() {
      return toJS(self.isLoading);
    },
  }))
  .actions((self) => {
    const setArticlesCount = (count: number) => {
      self.articles = count;
    };
    const setUsersCount = (count: number) => {
      self.users = count;
    };
    const setIsLoading = (status: boolean) => {
      self.isLoading = status;
    };

    return {
      setUsersCount,
      setArticlesCount,
      setIsLoading,
    };
  });

export type IDashboardStore = Instance<typeof DashboardStore>;

export default DashboardStore;
