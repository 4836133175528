import { Input } from 'antd';
import cn from 'classnames';
import { FieldProps } from '../../../settings/utils/types';
import { useState } from 'react';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  UnlockOutlined,
} from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ReactNode } from 'react';

interface InputTextProps {
  setValue: (value: string) => void;
  value: string;
  className?: string;
  placeholder?: string;
  type?: FieldProps;
  minLength?: number;
  maxLength?: number;
  onKeyPress?: any;
  autoFocus?: boolean;
  widthIcon?: boolean;
  size?: SizeType;
  icon?: ReactNode;
  name?: string;
  disabled?: boolean;
}
export const InputPassword = ({
  setValue,
  value,
  className,
  type,
  minLength,
  maxLength,
  onKeyPress,
  placeholder = 'password',
  autoFocus = false,
  widthIcon = false,
  size = 'large',
  name = 'password',
  icon = <UnlockOutlined />,
  disabled,
}: InputTextProps) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <Input.Password
      name={name}
      autoFocus={autoFocus}
      onKeyPress={onKeyPress}
      type={type}
      placeholder={placeholder}
      className={cn('input', 'passwordInput', className, {
        showPassword: show,
      })}
      disabled={!!disabled}
      size={size}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      autoComplete="off"
      maxLength={maxLength}
      minLength={minLength}
      prefix={widthIcon ? icon : undefined}
      iconRender={(visible) =>
        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
      }
    />
  );
};
