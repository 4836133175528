import { useLocalObservable } from 'mobx-react-lite';
import { IStore } from './store';
import React from 'react';

export const storeContext = React.createContext<IStore | null>(null);

interface IProvider {
	children: React.ReactNode;
	store: IStore;
}

const StoreProvider = ({ children, store }: IProvider): JSX.Element => {
	const storeHook = useLocalObservable(() => store);

	return <storeContext.Provider value={storeHook}>{children}</storeContext.Provider>;
};

export default StoreProvider;
