import cn from 'classnames';
import { useStore } from '../../../settings/stores/use-store';
import { observer } from 'mobx-react-lite';
import s from './aside.module.scss';
import { ITableSchema } from '../../../settings/stores/settings';
import UserInfo from './user-info';
import MenuItem from './menu-item';
import { MenuItemsValuesProps } from '../../../settings/utils/types';
import { useNavigate } from 'react-router-dom';

const Aside = () => {
  const {
    settingsStore: {
      // getDictionary,
      getIsOpenMenu,
      getTables,
      t,
      setActiveParentMenu,
      setActiveCategoryTitle,
      getContentTables,
    },
  } = useStore();
  const navigate = useNavigate();

  console.log('getTables', getTables);
  const getItems = (includes: boolean) =>
    getTables
      ?.filter(
        ({ tableName }) =>
          getContentTables.includes(tableName as string) === includes,
      )
      .map(({ tableName }) => tableName)
      .sort() as ITableSchema['tableName'][];
  const getLeads = (name: string) =>
    getTables
      ?.filter(({ tableName }) => tableName === name)
      .map(({ tableName }) => tableName)
      .sort() as ITableSchema['tableName'][];

  const menuItems: MenuItemsValuesProps[] = [
    {
      name: 'dashboard',
      title: t('Dashboard'),
      icon: 'DashboardOutlined',
      onClick: (name: string) => {
        setActiveParentMenu(name);
        setActiveCategoryTitle(t(name));
        navigate('/');
      },
    },
    {
      name: 'GetLeads',
      title: t('GetLeads'),
      items: getLeads('getLeads'),
      icon: 'OrderedListOutlined',
      onClick: (name: string) => {
        setActiveParentMenu(name);
      },
    },
    {
      name: 'tables',
      title: t('Tables'),
      items: getItems(true),
      icon: 'OrderedListOutlined',
      onClick: (name: string) => {
        setActiveParentMenu(name);
      },
    },
    // {
    //   name: 'settings',
    //   title: t('Settings'),
    //   items: getItems(false),
    //   icon: 'HddOutlined',
    //   onClick: (name: string) => {
    //     setActiveParentMenu(name);
    //   },
    // },
  ];

  return (
    <div className={cn(s.aside, { [s.isActive]: getIsOpenMenu })}>
      <ul className={s.menu}>
        <UserInfo />
        {menuItems?.map(({ title, items, icon, name, onClick }) => (
          <MenuItem
            key={title}
            title={title}
            items={items}
            icon={icon}
            name={name}
            onClick={onClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default observer(Aside);
