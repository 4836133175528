import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import s from '../aside.module.scss';
import { CustomButton } from '../../../ui/custom-button';
import SubMenu from '../sub-menu';
import { MenuItemsValuesProps } from '../../../../settings/utils/types';
import { useStore } from '../../../../settings/stores/use-store';
interface MenuItemProps {
  title: MenuItemsValuesProps['title'];
  name: MenuItemsValuesProps['name'];
  icon: MenuItemsValuesProps['icon'];
  onClick: MenuItemsValuesProps['onClick'];
  items: MenuItemsValuesProps['items'];
}
const MenuItem = ({ title, name, icon, items, onClick }: MenuItemProps) => {
  const {
    settingsStore: { getActiveParentMenu },
  } = useStore();
  return (
    <li
      key={name}
      className={cn(s.menuTopItem, {
        [s.isActive]: getActiveParentMenu === name,
      })}
    >
      <CustomButton
        className={s.menuTopItemButton}
        text={title}
        withIcon
        icon={icon}
        onClick={() => {
          if (!items) {
            onClick();
          } else {
            onClick(name);
          }
        }}
        type="link"
      />
      {items && <SubMenu items={items} name={name} />}
    </li>
  );
};
export default observer(MenuItem);
