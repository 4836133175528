import { CustomButton } from '../ui/custom-button';
import { ActionTypes } from '../../settings/utils/types';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../settings/stores/use-store';

const DeletePopUp = () => {
  const {
    settingsStore: { t, setActionType, setConfirmAction, setOpenModal },
  } = useStore();

  return (
    <div className="actionModal">
      <div className="actionModalBody">
        <h2 className="actionModalBodyTitle">
          Вы действительно хотите уделить этот контент?
        </h2>
        <div className="actionModalBodyButtons">
          <CustomButton
            type="primary"
            onClick={() => {
              setActionType(null);
              setConfirmAction(false);
              setOpenModal(false);
            }}
            text={t('Cancel')}
          />
          <CustomButton
            type="default"
            danger
            onClick={() => {
              setActionType(ActionTypes.DELETE);
              setConfirmAction(true);
            }}
            text={t('Confirm')}
          />
        </div>
      </div>
    </div>
  );
};
export default observer(DeletePopUp);
