import { Tag } from 'antd';
import { Status, StatusSign } from '../../settings/utils/types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../settings/stores/use-store';
import { observer } from 'mobx-react-lite';
import s from './table-row-item.module.scss';
import { InputPassword } from '../ui/input-password';
import { useCallback } from 'react';
import { CustomButton } from '../ui/custom-button';
interface TableRowItemProps {
  el: string;
  item: any;
}
const TableRowItem = ({ el, item }: TableRowItemProps) => {
  const handleCopy = useCallback(async (elem: string) => {
    try {
      await navigator.clipboard.writeText(elem as string);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    settingsStore: {
      t,
      rusDateFormat,
      getAuthors,
      getCategories,
      getPartners,
      getServices,
      setActiveTable,
      getCurrency,
    },
  } = useStore();

  switch (el) {
    case 'sort':
    case 'status':
    case 'isPriceShow':
    case 'isFormShow':
    case 'isTimerShow':
    case 'isAdmin':
      return (
        <Tag color={item[el] === Status[1] ? '#1ab394' : '#ED5565'}>
          {item[el] != null ? t(item[el]) : t(StatusSign.Disabled)}
        </Tag>
      );
    case 'start':
      return <Tag color={'#1ab394'}>{t(item[el])}</Tag>;
    case 'title':
      return (
        <div>
          <span
            onClick={() => {
              setActiveTable(item['id']);
              navigate(`${pathname}/${item['id']}`);
            }}
            className="linkField"
          >
            {item[el]}
          </span>
          {item['link'] && (
            <Link to={item['link']} target="_blank">
              <CustomButton
                onClick={() => handleCopy(item['link'])}
                withTooltip
                tooltip="Нажми, чтобы перейти в кабинет"
                icon="ExportOutlined"
                withIcon
              />
            </Link>
          )}
        </div>
      );
    case 'description':
    case 'content':
      return (
        <div
          className="contentField"
          dangerouslySetInnerHTML={{
            __html: `${item[el]?.slice(0, 30)}...`,
          }}
        />
      );
    case 'createdAt':
      return <span>{item[el] ? rusDateFormat(item[el]) : ''}</span>;
    case 'updatedAt':
      return <span>{item[el] ? rusDateFormat(item[el]) : ''}</span>;
    case 'category':
      return (
        <span>{getCategories.find(({ id }) => id === item[el])?.title}</span>
      );
    case 'partner':
      return (
        <span>{getPartners?.find(({ id }) => id === item[el])?.title}</span>
      );
    case 'cost':
      return (
        <span>{`${Intl.NumberFormat('ru-RU', {
          style: 'currency',
          currency:
            getCurrency?.find(({ id }) => id === item['currency'])?.key ||
            'RUB',
        }).format(item[el])}`}</span>
      );
    case 'service':
      return (
        <span>{getServices?.find(({ id }) => id === item[el])?.title}</span>
      );
    case 'login':
      return (
        <CustomButton
          withTooltip
          tooltip="Нажми, чтобы скопировать логин"
          text={item[el]}
          onClick={() => handleCopy(item[el])}
          style={{ whiteSpace: 'nowrap' }}
        />
      );
    case 'password':
      return (
        <div className={s.oneLine}>
          <InputPassword
            value={item[el]}
            setValue={() => console.log(item[el])}
          />
          <CustomButton
            withTooltip
            tooltip="Нажми, чтобы скопировать пароль"
            onClick={() => handleCopy(item[el])}
            icon="CopyOutlined"
            withIcon
          />
        </div>
      );
    case 'image':
      return (
        <div className={s.tableImage}>
          {item[el] ? (
            <img
              src={`${process.env.REACT_APP_BACKEND_HOST_ORIGINAL}/${item[el]}`}
              alt={item[el]}
            />
          ) : (
            <></>
          )}
        </div>
      );
    case 'author':
      const authors = item[el]?.split(',').map((item: string) => +item);
      const authorsTitles = getAuthors?.filter(({ id }) =>
        authors?.includes(id),
      );
      return (
        <div>
          {authorsTitles.map(
            ({ title }, index) =>
              `${title}${
                (index === 0 && authorsTitles.length === 1) ||
                index === authorsTitles.length - 1
                  ? ''
                  : ', '
              }`,
          ) || ''}
        </div>
      );
    default:
      return <div>{item[el]}</div>;
  }
};
export default observer(TableRowItem);
