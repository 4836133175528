import { DownOutlined } from '@ant-design/icons';
import s from './sub-menu.module.scss';
import cn from 'classnames';
import SubMenuItem from '../sub-menu-item';
import { useStore } from '../../../../settings/stores/use-store';
import { observer } from 'mobx-react-lite';
import { ITableSchema } from '../../../../settings/stores/settings';
interface SubMenuProps {
  items: ITableSchema['tableName'][];
  name: ITableSchema['tableName'];
}

const SubMenu = ({ items, name }: SubMenuProps) => {
  const {
    settingsStore: { t, getActiveParentMenu },
  } = useStore();
  return (
    <>
      <DownOutlined rev="" className={s.arrow} />
      <ul
        className={cn(s.subMenu, {
          [s.isActive]: getActiveParentMenu === name,
        })}
      >
        {items.map((tableName) => (
          <SubMenuItem key={tableName} name={tableName} title={t(tableName)} />
        ))}
      </ul>
    </>
  );
};
export default observer(SubMenu);
