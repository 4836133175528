import s from './table.module.scss';
import { withRouter } from '../../settings/utils/withRouter';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../settings/stores/use-store';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

const Table = () => {
  const { id, uid } = useParams();

  const {
    settingsStore: { getActiveTableItem, fetchTableContent, setActiveTable },
    authStore: { getToken },
  } = useStore();

  useEffect(() => {
    if (!getActiveTableItem && uid) {
      fetchTableContent(getToken as string, id as string).then((res) => {
        if (res && res.status === 200) {
          setActiveTable(+uid as number);
        }
      });
    }
  }, [getActiveTableItem]);

  return (
    <div className={s.article}>
      {getActiveTableItem && (
        <>
          <h1 className={s.title}>{getActiveTableItem.title}</h1>
          <span className={s.originalyear}>
            {getActiveTableItem.originalyear}
          </span>
          <div
            className={s.content}
            dangerouslySetInnerHTML={{
              __html: getActiveTableItem.content as string,
            }}
          />
        </>
      )}
    </div>
  );
};

export default withRouter(observer(Table));
