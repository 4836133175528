// import { ReactComponent as IconArrows } from '../../assets/images/arrows.svg';
import {
  LogoutOutlined,
  MenuOutlined,
  OrderedListOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  FileImageOutlined,
  DashboardOutlined,
  HddOutlined,
  AppstoreOutlined,
  CopyOutlined,
  PlusSquareOutlined,
  SaveOutlined,
  ExportOutlined,
} from '@ant-design/icons';

interface SvgIconProps {
  icon?: string;
  fill?: string;
  stroke?: string;
  width?: number;
  height?: number;
  className?: string;
}

export const SvgIcons = ({
  icon,
  fill,
  className,
  width,
  height,
}: SvgIconProps) => {
  switch (icon) {
    // case 'receiveIcon':
    //     return <IconReceiveIcon />;
    case 'MenuOutlined':
      return <MenuOutlined />;
    case 'LogoutOutlined':
      return <LogoutOutlined />;
    case 'OrderedListOutlined':
      return <OrderedListOutlined />;
    case 'DeleteOutlined':
      return <DeleteOutlined />;
    case 'EditOutlined':
      return <EditOutlined />;
    case 'PlusOutlined':
      return <PlusOutlined />;
    case 'FileImageOutlined':
      return <FileImageOutlined />;
    case 'DashboardOutlined':
      return <DashboardOutlined />;
    case 'HddOutlined':
      return <HddOutlined />;
    case 'AppstoreOutlined':
      return <AppstoreOutlined />;
    case 'CopyOutlined':
      return <CopyOutlined />;
    case 'PlusSquareOutlined':
      return <PlusSquareOutlined />;
    case 'SaveOutlined':
      return <SaveOutlined />;
    case 'ExportOutlined':
      return <ExportOutlined />;
    default:
      return <></>;
  }
};
