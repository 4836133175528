import { Colors, ColorsProps } from '../utils/types';
import { IColorSchemaModel } from '../stores/settings';

export const colors: {
  [key in keyof ColorsProps as string]: IColorSchemaModel;
} = {
  [Colors.BLUE]: { first: '#4C4EC1', second: '#26E2E2', third: '#7DEEEE' },
  [Colors.GREEN]: { first: '#009A9A', second: '#06D26E', third: '#9BEDC5' },
  [Colors.RED]: { first: '#F31631', second: '#EC8F34', third: '#F4BC86' },
  [Colors.PURPLE]: { first: '#412394', second: '#F51FAC', third: '#F979CD' },
  [Colors.BLACK]: { first: '#142542', second: '#606A84', third: '#AAAFBB' },
};
