//@ts-ignore
import { getEnv, Instance, types as t } from 'mobx-state-tree';
import SettingsStore from './settings';
import {
  FieldType,
  Languages,
  Colors,
  Token,
  CurrencyEnum,
} from '../utils/types';
import { getCookie, setCookie } from 'cookies-next';
import { colors } from '../intl/colors';
import DashboardStore from '../stores/dashboard';
import TablesStore from '../stores/tables';
import AuthStore from '../stores/auth';
import { SHA256, enc } from 'crypto-js';

const Store = t
  .model('Store', {
    appLoaded: false,
    dashboardStore: t.optional(DashboardStore, {
      users: null,
      articles: null,
      isLoading: false,
    }),
    tablesStore: t.optional(TablesStore, {
      rowsCount: null,
    }),
    authStore: t.optional(AuthStore, {
      user: null,
      isAuth: null,
      token: null,
      isLoading: false,
    }),
    settingsStore: t.optional(SettingsStore, {
      activeLanguage: Languages.RU,
      activeColor: Colors.BLUE,
      activeColorScheme: colors[Colors.BLUE],
      activeTableArray: [],
      analysis: [],
      activeStep: 1,
      activeMenu: null,
      activeParentMenu: null,
      activeItem: null,
      activeCategoryTitle: null,
      actionType: null,
      actionId: null,
      authors: [],
      activeTable: null,
      activeTableItem: null,
      cities: [],
      confirmAction: false,
      categories: [],
      confirmPassword: null,
      codeWord: null,
      confirmCodeWord: null,
      currency: CurrencyEnum.RUB,
      contentTables: [
        'getLeads',
        'partners',
        'expenses',
        'accesses',
        'offers',
        'funnels',
        'services',
      ],
      dictionary: null,
      delete: false,
      email: null,
      excludedFormFields: [
        'id',
        'image',
        'createdAt',
        'updatedAt',
        'hashed_password',
        'salt',
        'token',
        'gallery',
      ],
      excludedTableFields: [
        'hashed_password',
        'salt',
        'token',
        'tags',
        'groups',
        'images',
        'content',
        'original',
        'analysis',
        'currency',
        'gallery',
        'link',
      ],
      fieldType: FieldType.password,
      groups: [],
      imageUpLoading: false,
      isAdaptive: false,
      isWrongPassword: true,
      isWrongEmail: true,
      isDisabledButton: true,
      isLoading: true,
      isOpenMenu: true,
      isFail: false,
      mistake: false,
      mistakeText: '',
      manifestType: [],
      nonContentTables: ['admins'],
      nickName: null,
      openModal: false,
      pageTitle: '',
      password: null,
      screenWidth: 0,
      successStep: true,
      sent: false,
      selectedTab: null,
      showPassword: false,
      tables: [],
      tags: [],
      walletName: null,
      partners: [],
      services: [],
      leads: [],
      expenses: [],
    }),
  })
  .views((self) => ({
    get api() {
      return getEnv(self).api;
    },
    get auth() {
      return getEnv(self).auth;
    },
    get qs() {
      return getEnv(self).qs;
    },
  }))
  .actions((self) => {
    const setHashToken = () => {
      const secretHash = SHA256(new Date().toString()).toString(enc.Hex);
      if (secretHash) {
        self.authStore.setToken(secretHash);
        setCookie(Token.id, secretHash);
        self.authStore.setIsLoading(false);
      }
      self.authStore.setIsAuth(false);
    };

    const getAuth = () => {
      setAppLoaded(false);
      if (getCookie(Token.id)) {
        self.authStore
          .fetchUser(getCookie(Token.id) as string)
          .then((res: any) => {
            if (res && res.status === 200) {
              self.settingsStore.fetchTablesList(getCookie(Token.id) as string);
            }
          });
      } else {
        setHashToken();
      }
    };

    const afterCreate = () => {
      setAppLoaded(false);
      getAuth();
      setAppLoaded(true);
    };

    const setAppLoaded = (status: boolean) => {
      self.appLoaded = status;
    };

    const dbTables = () => {
      return [
        {
          name: 'articles',
          title: self.settingsStore.t('Articles'),
          onChange: self.dashboardStore.setArticlesCount,
          count: self.dashboardStore.getArticlesCount,
        },
        {
          name: 'users',
          title: self.settingsStore.t('Users'),
          onChange: self.dashboardStore.setUsersCount,
          count: self.dashboardStore.getUsersCount,
        },
      ];
    };

    return {
      afterCreate,
      setAppLoaded,
      dbTables,
      setHashToken,
      getAuth,
    };
  });

export type IStore = Instance<typeof Store>;

export default Store;
