import s from './charts.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../settings/stores/use-store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';
import { LeadsTable } from './leads-table';
import { LeadsChart } from './leads-chart';
import ExpensesChart from './expenses-chart';
dayjs.extend(utc);

export const Charts = () => {
  const {
    authStore: { getToken },
    settingsStore: { getAllLeads },
  } = useStore();

  useEffect(() => {
    getAllLeads(getToken);
  }, []);

  return (
    <div className={s.charts}>
      <div className={s.chartsPart}>
        <LeadsChart />
        <LeadsTable />
      </div>
      <ExpensesChart />
    </div>
  );
};

export default observer(Charts);
