import s from '../../styles/pages/signin.module.scss';
import LoginForm from '../login-form';
import { HaveAnAccount } from '../login-form/have-an-account';
import { useStore } from '../../settings/stores/use-store';
import { ActionTypes, FormProps } from '../../settings/utils/types';
import { observer } from 'mobx-react-lite';
interface LoginBlockProps {
  actionType: ActionTypes;
  formItems: FormProps[];
}
const LoginBlock = ({ actionType, formItems }: LoginBlockProps) => {
  const {
    settingsStore: { t },
  } = useStore();

  return (
    <div>
      <h1 className={s.title}>{t('Sign in page')}</h1>
      <LoginForm
        formItems={formItems}
        buttonText={t('Sign in')}
        useApi
        actionType={actionType}
      />
      <HaveAnAccount
        link={'/register'}
        title={t('Don`t have an account? Create to use it')}
        buttonText={t('Sign up')}
      />
    </div>
  );
};
export default observer(LoginBlock);
