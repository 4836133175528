//@ts-ignore
import { flow, Instance, types as t, cast } from 'mobx-state-tree';
import { toJS } from 'mobx';
import {
  ActionTypes,
  Colors,
  CurrencyEnum,
  FieldType,
  getStatus,
  ICurrencyProps,
  Languages,
  ResponseProps,
  Status,
} from '../utils/types';
import { dictionary } from '../intl/dictionary';
import { colors } from '../intl/colors';
import { checkEmailValidator } from '../utils/check-email-validator';
import { requests } from '../api';
import dayjs from 'dayjs';

export const currencyList: ICurrencyProps[] = [
  { id: 1, key: CurrencyEnum.RUB, value: 1 },
  { id: 2, key: CurrencyEnum.EUR, value: 2 },
  { id: 3, key: CurrencyEnum.USD, value: 3 },
];

const PeopleSchema = t.model('PeopleSchema', {
  id: t.maybeNull(t.number),
  title: t.maybeNull(t.string),
  name: t.maybeNull(t.string),
  surname: t.maybeNull(t.string),
  sort: t.maybeNull(t.number),
  content: t.maybeNull(t.string),
  image: t.maybeNull(t.string),
  position: t.maybeNull(t.number),
});
export type IPeopleSchema = Instance<typeof PeopleSchema>;

const AllTablesSchema = t.model('AllTablesSchema', {
  analysis: t.maybeNull(t.union(t.number, t.string)),
  author: t.maybeNull(
    t.union(t.number, t.string, t.array(t.number), t.array(t.string)),
  ),
  content: t.maybeNull(t.string),
  category: t.maybeNull(t.union(t.number, t.string)),
  createdAt: t.maybeNull(t.string),
  cost: t.maybeNull(t.number),
  contact: t.maybeNull(t.string),
  currency: t.maybeNull(
    t.union(
      t.enumeration<CurrencyEnum>(
        'currency',
        Object.values(CurrencyEnum) as CurrencyEnum[],
      ),
      t.number,
    ),
  ),
  description: t.maybeNull(t.string),
  email: t.maybeNull(t.string),
  first: t.maybeNull(t.string),
  gallery: t.maybeNull(t.string),
  groups: t.maybeNull(
    t.union(t.number, t.string, t.array(t.number), t.array(t.string)),
  ),
  id: t.number,
  image: t.maybeNull(t.string),
  isAdmin: t.maybeNull(t.union(t.number, t.string)),
  isFormShow: t.maybeNull(t.union(t.number, t.string)),
  isTimerShow: t.maybeNull(t.union(t.number, t.string)),
  isPriceShow: t.maybeNull(t.union(t.number, t.string)),
  key: t.maybeNull(t.union(t.number, t.string)),
  link: t.maybeNull(t.string),
  label: t.maybeNull(t.union(t.number, t.string)),
  login: t.maybeNull(t.string),
  name: t.maybeNull(t.string),
  nickname: t.maybeNull(t.string),
  manifestType: t.maybeNull(t.number),
  original: t.maybeNull(t.string),
  originalyear: t.maybeNull(t.string),
  pathName: t.maybeNull(t.string),
  publishDate: t.maybeNull(t.string),
  password: t.maybeNull(t.string),
  partner: t.maybeNull(t.union(t.string, t.number)),
  partnerOfferId: t.maybeNull(t.union(t.number, t.string)),
  partnerApiKey: t.maybeNull(t.union(t.number, t.string)),
  ru: t.maybeNull(t.string),
  status: t.maybeNull(t.union(t.number, t.string)),
  second: t.maybeNull(t.string),
  service: t.maybeNull(t.union(t.string, t.number)),
  sort: t.maybeNull(t.number),
  start: t.maybeNull(t.number),
  surname: t.maybeNull(t.string),
  title: t.maybeNull(t.union(t.number, t.string)),
  tags: t.maybeNull(
    t.union(t.number, t.string, t.array(t.number), t.array(t.string)),
  ),
  third: t.maybeNull(t.string),
  username: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
  value: t.maybeNull(t.union(t.number, t.string)),
  word: t.maybeNull(t.string),
});
export type IAllTablesSchema = Instance<typeof AllTablesSchema>;

const ArticleSchema = t.model('ArticleSchema', {
  id: t.number,
  title: t.string,
  content: t.maybeNull(t.string),
  original: t.maybeNull(t.string),
  category: t.maybeNull(t.number),
  sort: t.maybeNull(t.number),
  author: t.number,
  status: t.number,
  tags: t.string,
  image: t.maybeNull(t.string),
  originalyear: t.maybeNull(t.string),
  analysis: t.maybeNull(t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
  manifestType: t.maybeNull(t.number),
});
export type IArticleSchema = Instance<typeof ArticleSchema>;

const TableSchema = t.model('TableItemsSchema', {
  tableName: t.string,
  fields: t.maybeNull(t.array(t.string)),
  count: t.maybeNull(t.union(t.string, t.number)),
  rows: t.maybeNull(
    t.array(
      t.union(
        t.string,
        t.number,
        t.literal(Status.Active),
        t.literal(Status.Disabled),
      ),
    ),
  ),
});
export type ITableSchema = Instance<typeof TableSchema>;

const ColorSchema = t.model('ColorSchema', {
  first: t.string,
  second: t.string,
  third: t.string,
});
export type IColorSchemaModel = Instance<typeof ColorSchema>;

const ManifestTypeSchema = t.model('ManifestTypeSchema', {
  id: t.number,
  title: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
  content: t.maybeNull(t.string),
});

export type IManifestTypeSchema = Instance<typeof ManifestTypeSchema>;

const CitiesTypeSchema = t.model('ManifestTypeSchema', {
  id: t.number,
  title: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
  content: t.maybeNull(t.string),
});

export type ICitiesTypeSchema = Instance<typeof CitiesTypeSchema>;

const AuthorsSchema = t.model('AuthorsSchema', {
  id: t.number,
  title: t.string,
  status: t.maybeNull(t.union(t.number, t.string)),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
  content: t.maybeNull(t.string),
  groups: t.maybeNull(t.number),
  gallery: t.maybeNull(t.string),
});
export type IAuthorsSchema = Instance<typeof AuthorsSchema>;

const GroupsSchema = t.model('GroupsSchema', {
  id: t.number,
  title: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});
export type IGroupsSchema = Instance<typeof GroupsSchema>;

const TagsSchema = t.model('TagsSchema', {
  id: t.number,
  title: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});
export type ITagsSchema = Instance<typeof TagsSchema>;

const CategoriesSchema = t.model('CategoriesSchema', {
  id: t.number,
  title: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});

export type ICategoriesSchema = Instance<typeof CategoriesSchema>;

const AnalysisSchema = t.model('AnalysisSchema', {
  id: t.number,
  title: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});

export type IAnalysisSchema = Instance<typeof AnalysisSchema>;

const PartnersSchema = t.model('PartnersSchema', {
  id: t.number,
  title: t.string,
  contact: t.string,
  content: t.string,
  service: t.number,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});

export type IPartnersSchema = Instance<typeof PartnersSchema>;

const ExpensesSchema = t.model('ExpensesSchema', {
  id: t.number,
  title: t.string,
  partner: t.number,
  cost: t.number,
  currency: t.number,
  content: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});

export type IExpensesSchema = Instance<typeof ExpensesSchema>;

const LeadsSchema = t.model('LeadsSchema', {
  id: t.number,
  content: t.maybeNull(t.string),
  apiKey: t.maybeNull(t.string),
  link: t.maybeNull(t.string),
  offerId: t.maybeNull(t.union(t.string, t.number)),
  countryCode: t.maybeNull(t.union(t.string, t.number)),
  createdAt: t.string,
  name: t.maybeNull(t.string),
  phone: t.maybeNull(t.string),
  streamCode: t.maybeNull(t.union(t.string, t.number)),
  landingUrl: t.maybeNull(t.string),
  ip: t.maybeNull(t.string),
  userInfo: t.maybeNull(t.string),
  userAgent: t.maybeNull(t.string),
  host: t.maybeNull(t.string),
  deposit: t.maybeNull(t.number),
  status: t.number,
  fake: t.number,
  updatedAt: t.maybeNull(t.string),
  partner: t.maybeNull(t.number),
  product: t.maybeNull(t.string),
  partnerDomain: t.maybeNull(t.string),
});

export type ILeadsSchema = Instance<typeof LeadsSchema>;

const ServicesSchema = t.model('ServicesSchema', {
  id: t.number,
  title: t.string,
  contact: t.string,
  content: t.string,
  status: t.union(t.number, t.string),
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});
export type IServicesSchema = Instance<typeof ServicesSchema>;

export type ICurrencySchema = Instance<typeof ServicesSchema>;

const DictionarySchema = t.model('DictionarySchema', {
  word: t.string,
  ru: t.string,
});

export type IDictionarySchema = Instance<typeof DictionarySchema>;
const SettingsStore = t
  .model('Settings', {
    activeLanguage: t.maybeNull(
      t.union(t.literal(Languages.RU), t.literal(Languages.EN)),
    ),
    activeColor: t.maybeNull(
      t.union(
        t.enumeration<Colors>('colors', Object.values(Colors) as Colors[]),
      ),
    ),
    activeColorScheme: t.maybeNull(ColorSchema),
    activeTableArray: t.maybeNull(t.array(AllTablesSchema)),
    activeTableItem: t.maybeNull(t.number),
    activeStep: t.number,
    activeMenu: t.maybeNull(t.union(t.number, t.string)),
    activeParentMenu: t.maybeNull(t.union(t.number, t.string)),
    activeCategoryTitle: t.maybeNull(t.string),
    actionId: t.maybeNull(t.number),
    authors: t.array(AuthorsSchema),
    analysis: t.array(AnalysisSchema),
    actionType: t.maybeNull(
      t.union(
        t.enumeration<ActionTypes>(
          'actionType',
          Object.values(ActionTypes) as ActionTypes[],
        ),
      ),
    ),
    activeItem: t.maybeNull(t.string),
    activeTable: t.maybeNull(t.array(TableSchema)),
    currency: t.maybeNull(t.string),
    confirmPassword: t.maybeNull(t.string),
    codeWord: t.maybeNull(t.string),
    confirmCodeWord: t.maybeNull(t.string),
    categories: t.array(CategoriesSchema),
    contentTables: t.array(t.string),
    cities: t.array(CitiesTypeSchema),
    confirmAction: t.boolean,
    delete: t.boolean,
    dictionary: t.maybeNull(t.array(DictionarySchema)),
    excludedFormFields: t.array(t.string),
    excludedTableFields: t.array(t.string),
    email: t.maybeNull(t.string),
    fieldType: t.maybeNull(
      t.union(
        t.enumeration<FieldType>(
          'fieldType',
          Object.values(FieldType) as FieldType[],
        ),
      ),
    ),
    groups: t.array(GroupsSchema),
    isAdaptive: t.boolean,
    isFail: t.boolean,
    isWrongPassword: t.maybeNull(t.boolean),
    isWrongEmail: t.maybeNull(t.boolean),
    isDisabledButton: t.boolean,
    isLoading: t.boolean,
    isOpenMenu: t.boolean,
    imageUpLoading: t.boolean,
    mistake: t.boolean,
    mistakeText: t.string,
    manifestType: t.array(ManifestTypeSchema),
    nonContentTables: t.array(t.string),
    nickName: t.maybeNull(t.string),
    openModal: t.boolean,
    pageTitle: t.string,
    password: t.maybeNull(t.string),
    sent: t.boolean,
    screenWidth: t.number,
    showPassword: t.boolean,
    successStep: t.boolean,
    selectedTab: t.maybeNull(t.string),
    tables: t.maybeNull(t.array(TableSchema)),
    tags: t.array(TagsSchema),
    walletName: t.maybeNull(t.string),
    partners: t.maybeNull(t.array(PartnersSchema)),
    services: t.maybeNull(t.array(ServicesSchema)),
    leads: t.array(LeadsSchema),
    expenses: t.array(ExpensesSchema),
  })
  .views((self) => ({
    get getOpenModal() {
      return toJS(self['openModal']);
    },
    get getDictionary() {
      return toJS(self['dictionary']);
    },
    get getExcludedFields() {
      return toJS(self['excludedFormFields']);
    },
    get getContentTables() {
      return toJS(self['contentTables']);
    },
    get getNonContentTables() {
      return toJS(self['nonContentTables']);
    },
    get getManifestType() {
      return toJS(self['manifestType']);
    },
    get getExcludedTableFields() {
      return toJS(self['excludedTableFields']);
    },
    get getImageUpLoading() {
      return toJS(self.imageUpLoading);
    },
    get getSent() {
      return toJS(self.sent);
    },
    get getDelete() {
      return toJS(self.delete);
    },
    get getActiveTableArray() {
      return toJS(self.activeTableArray);
    },
    get getIsWrongPassword() {
      return toJS(self.isWrongPassword);
    },
    get getConfirmAction() {
      return toJS(self.confirmAction);
    },
    get getActiveItem() {
      return toJS(self.activeItem);
    },
    get getActiveCategoryTitle() {
      return toJS(self.activeCategoryTitle);
    },
    get getCategories() {
      return toJS(self.categories);
    },
    get getAuthors() {
      return toJS(self.authors);
    },
    get getCities() {
      return toJS(self.cities);
    },
    get getTags() {
      return toJS(self.tags);
    },
    get getAnalysis() {
      return toJS(self.analysis);
    },
    get getPartners() {
      return toJS(self.partners);
    },
    get getLeads() {
      return toJS(self.leads);
    },
    get getExpenses() {
      const expenses: IExpensesSchema[] = toJS(
        self['expenses'],
      ) as IExpensesSchema[];
      return expenses.map(({ title, cost }) => ({
        name: title,
        data: [cost, 0],
      })) as { name: string; data: [number, number] }[];
    },
    get getLeadsToChart() {
      // dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).set('second', 0) - вчера
      // dayjs().add(1, 'day').set('hour', 0).set('minute', 0).set('second', 0) - вчера
      const leads: ILeadsSchema[] = toJS(self['leads']) as ILeadsSchema[];

      function getMoscowDayTime(date: string) {
        return dayjs(date).set('hour', 3).valueOf();
      }
      function getFilteredLeads(date: string) {
        return leads.filter(
          (lead) => dayjs(lead['createdAt']).format('YYYY-MM-DD') === date,
        ) as ILeadsSchema[];
      }
      function getFilteredLeadsUseStatus(date: string) {
        return getFilteredLeads(date).filter((lead) => lead['status'] === 1)
          .length as number;
      }
      function getFilteredLeadsUseFake(date: string) {
        return getFilteredLeads(date).filter((lead) => lead['fake'] === 1)
          .length as number;
      }
      const leadsDatesBlock: string[] = Array.from(
        new Set(
          leads
            .map(
              (lead) => dayjs(lead['createdAt']).format('YYYY-MM-DD') as string,
            )
            .sort((a, b) => (dayjs(a) > dayjs(b) ? 1 : -1)),
        ),
      ) as string[];

      function chartLeadsItems(type: string) {
        return leadsDatesBlock.map((date) => {
          let count: number = 0;
          switch (type) {
            case 'all':
              count = getFilteredLeads(date).length;
              break;
            case 'active':
              count = getFilteredLeadsUseStatus(date);
              break;
            case 'fake':
              count = getFilteredLeadsUseFake(date);
              break;
          }
          return [getMoscowDayTime(date), count];
        });
      }
      const chartLeads = chartLeadsItems('all');
      const chartLeadsIsActive = chartLeadsItems('active');
      const chartLeadsIsFake = chartLeadsItems('fake');

      const tableView = leadsDatesBlock.map((date, index) => {
        return {
          key: `${index + 1}`,
          date: dayjs(date).format('DD.MM.YYYY'),
          waiting: 0,
          all: getFilteredLeads(date).length as number,
          active: getFilteredLeadsUseStatus(date) as number,
          fake: getFilteredLeadsUseFake(date) as number,
        };
      });

      return { chartLeadsIsActive, chartLeads, chartLeadsIsFake, tableView };
    },
    get getCurrency() {
      return currencyList as ICurrencyProps[];
    },
    get getServices() {
      return toJS(self.services);
    },
    get getGroups() {
      return toJS(self.groups);
    },
    get getPageTitle() {
      return toJS(self.pageTitle);
    },
    get getActiveMenu() {
      return toJS(self.activeMenu);
    },
    get getActiveParentMenu() {
      return toJS(self.activeParentMenu);
    },
    get getTables() {
      return toJS(self.tables);
    },
    get getActiveTable() {
      return toJS(
        self.tables?.filter(({ tableName }) => tableName === self.activeItem),
      );
    },
    get getOneActiveTable() {
      return toJS(self.activeTable);
    },
    get getIsOpenMenu() {
      return toJS(self.isOpenMenu);
    },
    get getIsTablesLoading() {
      return toJS(self.isLoading);
    },
    get getSelectedTab() {
      return toJS(self.selectedTab);
    },
    get getIsWrongEmail() {
      return toJS(self.isWrongEmail);
    },
    get getIsDisabledButton() {
      return toJS(self.isDisabledButton);
    },
    get getActiveLanguage() {
      return toJS(self.activeLanguage);
    },
    get getShowPassword() {
      return toJS(self.showPassword);
    },
    get getScreenWidth() {
      return toJS(self.screenWidth);
    },
    get getIsAdaptive() {
      return toJS(self.isAdaptive);
    },
    get getActiveColor() {
      return toJS(self.activeColor);
    },
    get getActiveColorScheme() {
      return toJS(self.activeColorScheme);
    },
    get getActiveStep() {
      return toJS(self.activeStep);
    },
    get getSuccessStep() {
      return toJS(self.successStep);
    },
    get getIsFail() {
      return toJS(self.isFail);
    },
    get getEmail() {
      return toJS(self.email);
    },
    get getNickName() {
      return toJS(self.nickName);
    },
    get getEmailLength() {
      return toJS(self.email?.length);
    },
    get getPassword() {
      return toJS(self.password);
    },
    get getPasswordLength() {
      return toJS(self.password?.length);
    },
    get getConfirmPassword() {
      return toJS(self.confirmPassword);
    },
    get getConfirmPasswordLength() {
      return toJS(self.confirmPassword?.length);
    },
    get getCodeWord() {
      return toJS(self.codeWord);
    },
    get getCodeWordLength() {
      return toJS(self.codeWord?.length);
    },
    get getConfirmCodeWord() {
      return toJS(self.confirmCodeWord);
    },
    get getConfirmCodeWordLength() {
      return toJS(self.confirmCodeWord?.length);
    },
    get getWalletName() {
      return toJS(self.walletName);
    },
    get getWalletNameLength() {
      return toJS(self.walletName?.length);
    },
    get getFiledType() {
      return toJS(self.fieldType);
    },
    get getIsValidEmail() {
      return self.email && checkEmailValidator(self.email);
    },
    get getLanguages() {
      return Object.keys(Languages) as Languages[];
    },
    get getMistake() {
      return toJS(self.mistake);
    },
    get getMistakeText() {
      return toJS(self.mistakeText);
    },
    get getActionType() {
      return toJS(self.actionType);
    },
    get getActionId() {
      return toJS(self.actionId);
    },
    get getActiveTableItem() {
      return toJS(
        self.activeTableArray?.find(
          (item) => item.id === self.activeTableItem,
        ) as IAllTablesSchema,
      ) as unknown as { [key: string]: string | number };
    },
  }))
  .actions((self) => {
    const setImageUpLoading = (status: boolean) => {
      self.imageUpLoading = status;
    };
    const setSent = (status: boolean) => {
      self.sent = status;
    };
    const setDelete = (status: boolean) => {
      self.delete = status;
    };
    const setActiveTable = (id: number) => {
      self.activeTableItem = id;
    };
    const setActiveTableArray = (activeTable: any) => {
      self.activeTableArray = cast(activeTable);
    };
    const setConfirmAction = (confirmAction: boolean) => {
      self.confirmAction = confirmAction;
    };
    const setOpenModal = (openModal: boolean) => {
      self.openModal = openModal;
    };
    const setTables = (table: ITableSchema[]) => {
      self.tables = cast(table);
    };
    const setCategories = (categories: ICategoriesSchema[]) => {
      self.categories = cast(categories);
    };
    const setAnalysis = (analysis: IAnalysisSchema[]) => {
      self.analysis = cast(analysis);
    };
    const setPartners = (partners: IPartnersSchema[]) => {
      self.partners = cast(partners);
    };
    const setLeads = (leads: ILeadsSchema[]) => {
      self['leads'] = cast(leads as any);
    };
    const setExpenses = (expenses: IExpensesSchema[]) => {
      self['expenses'] = cast(expenses as any);
    };
    const setServices = (services: IServicesSchema[]) => {
      self.services = cast(services);
    };
    const setAuthors = (authors: IAuthorsSchema[]) => {
      self.authors = cast(authors);
    };
    const setTags = (tags: ITagsSchema[]) => {
      self.tags = cast(tags);
    };
    const setGroups = (groups: IGroupsSchema[]) => {
      self.groups = cast(groups);
    };
    const setManifestType = (manifestType: IManifestTypeSchema[]) => {
      self.manifestType = cast(manifestType);
    };
    const setCities = (cities: ICitiesTypeSchema[]) => {
      self.cities = cast(cities);
    };
    const setActionType = (actionType: ActionTypes | null) => {
      self.actionType = actionType;
    };
    const setActionId = (id: number) => {
      self.actionId = id;
    };
    const setActiveCategoryTitle = (title: string | null) => {
      self.activeCategoryTitle = title;
    };
    const setPageTitle = (title: string) => {
      self.pageTitle = title;
    };
    const setActiveItem = (item: string | null) => {
      self.activeItem = item;
    };
    const setActiveParentMenu = (item: string) => {
      self.activeParentMenu = item;
    };
    const setIsOpenMenu = (status: boolean) => {
      self.isOpenMenu = status;
    };
    const setIsWrongEmail = (status: boolean) => {
      self.isWrongEmail = status;
    };
    const setIsLoading = (status: boolean) => {
      self.isLoading = status;
    };
    const setSelectedTab = (tab: string) => {
      self.selectedTab = tab;
    };
    const setEmail = (email: string) => {
      self.email = email;
      if (checkEmailValidator(self.email)) {
        setIsDisabledButton(false);
      } else {
        setIsDisabledButton(true);
      }
    };
    const setNickName = (nickName: string) => {
      self.nickName = nickName;
    };
    const setIsDisabledButton = (status: boolean) => {
      self.isDisabledButton = status;
    };
    const setMistake = (mistake: boolean) => {
      self.mistake = mistake;
    };
    const setShowPassword = (status: boolean) => {
      self.showPassword = status;
    };
    const setMistakeText = (mistakeText: string) => {
      self.mistakeText = mistakeText;
    };
    const setActiveLanguage = (language: Languages) => {
      self['activeLanguage'] = language;
    };

    const setScreenWidth = (width: number) => {
      self['screenWidth'] = width;
    };

    const setIsAdaptive = (status: boolean) => {
      self['isAdaptive'] = status;
    };

    const dateFormat = (value: string) => {
      const targetDate = new Date(value);
      return new Intl.DateTimeFormat(
        `${self['activeLanguage']}-${
          self['activeLanguage'] === Languages.RU
            ? self['activeLanguage'].toUpperCase()
            : 'GB'.toUpperCase()
        }`,
      ).format(targetDate);
    };

    const numberFormat = (
      value: number,
      locale?: string,
      maxDigits?: number,
    ) => {
      return (
        self['activeLanguage'] &&
        new Intl.NumberFormat(
          `${
            locale
              ? locale
              : `${self['activeLanguage']}-${self['activeLanguage'].toUpperCase()}`
          }`,
          {
            maximumFractionDigits: maxDigits || maxDigits === 0 ? maxDigits : 6,
          },
        ).format(value)
      );
    };

    const translate = (word: string) => {
      const textLine = dictionary[word];
      // const textLine = self.dictionary?.find((item) => item.word === word)?.ru;
      //   console.log('self.dictionary', self.dictionary);
      // console.log('self.dictionary', self.dictionary);
      let resultText = word;

      if (textLine) {
        // @ts-ignore
        resultText = textLine[self.activeLanguage];
      }

      return resultText as string;
    };
    const t = translate;

    const setActiveColorScheme = (scheme: IColorSchemaModel) => {
      self['activeColorScheme'] = scheme;
    };

    const setActiveStep = (step: number) => {
      self['activeStep'] = step;
    };

    const setActiveMenu = (item: number | string | null) => {
      self['activeMenu'] = item;
    };

    const setSuccessStep = (status: boolean) => {
      self['successStep'] = status;
    };

    const setIsFail = (status: boolean) => {
      self['isFail'] = status;
    };

    const setConfirmPassword = (confirmPassword: string) => {
      if (confirmPassword === self['password']) {
        setMistake(false);
        setIsDisabledButton(false);
      } else {
        setIsDisabledButton(true);
        setMistake(true);
        setMistakeText(checkPassword(confirmPassword).mistake);
      }
      self['confirmPassword'] = confirmPassword;
    };

    const setCodeWord = (codeWord: string) => {
      self['codeWord'] = codeWord;

      if (codeWord.length >= 2) {
        setIsDisabledButton(false);
      } else {
        setIsDisabledButton(true);
        setMistake(true);
      }
    };

    const setConfirmCodeWord = (confirmCodeWord: string) => {
      self['confirmCodeWord'] = confirmCodeWord;
      if (confirmCodeWord === self['codeWord']) {
        setMistake(false);
        setIsDisabledButton(false);
      } else {
        setIsDisabledButton(true);
        setMistake(true);
      }
    };

    const setActiveBg = () => {
      let bgColor = '';
      // switch (self.activeColor) {
      //   case 'blue':
      //     bgColor = BlueBg;
      //     break;
      //   case 'red':
      //     bgColor = RedBg;
      //     break;
      //   case 'purple':
      //     bgColor = PurpleBg;
      //     break;
      //   case 'black':
      //     bgColor = BlackBg;
      //     break;
      //   case 'green':
      //     bgColor = GreenBg;
      //     break;
      // }
      return bgColor;
    };

    const setFiledType = (fieldType: FieldType) => {
      self['fieldType'] = fieldType;
    };

    const setActiveColor = (color: Colors) => {
      self['activeColor'] = color;
    };

    const setWalletName = (walletName: string) => {
      self['walletName'] = walletName;
    };
    const setDictionary = (words: IDictionarySchema[]) => {
      self['dictionary'] = cast(words as any);
    };

    const rusDateFormat = (date: string) => dayjs(date).format('DD.MM.YYYY');
    const dateBaseDateFormat = (date: string) =>
      dayjs(date).format('YYYY-MM-DD');

    const checkPassword = (password: string) => {
      const hasOnlyAllowedSymbols =
        /^[A-Za-z0-9~!?@#$%\^&*_\-+()\[\]{}></\\|\"'.,:;]{8,128}$/.test(
          password,
        );
      const hasUpperLetters = /[A-Z]/.test(password);
      const hasLowerLetters = /[a-z]/.test(password);
      const hasNumbers = /[0-9]/.test(password);
      const hasSpecSymbols = /[~!?@#$%\^&*_\-+()\[\]{}></\\|\"'.,:;]/.test(
        password,
      );

      let mistakeText = '';
      if (password.length < 8) {
        mistakeText = 'password: too short';
      } else if (password.length > 128) {
        mistakeText = 'password: too long';
      } else if (hasOnlyAllowedSymbols) {
        mistakeText =
          'password: allowed only latin letters, numbers and special symbols (~!?@#$%^&*_-+()[]{}></|"\'.,:;)';
      } else if (hasUpperLetters) {
        mistakeText = 'password: must contain at least one upper latin letter';
      } else if (hasLowerLetters) {
        mistakeText = 'password: must contain at least one lower latin letter';
      } else if (hasNumbers) {
        mistakeText = 'password: must contain at least one number';
      } else if (hasSpecSymbols) {
        mistakeText =
          'password: must contain at least one special symbol (~!?@#$%^&*_-+()[]{}></|"\'.,:;)';
      }
      return {
        status:
          hasOnlyAllowedSymbols &&
          hasUpperLetters &&
          hasLowerLetters &&
          hasNumbers &&
          hasSpecSymbols,
        mistake: mistakeText,
      };
    };

    const setPassword = (password: string) => {
      if (!checkPassword(password).status) {
        setMistake(true);
        setMistakeText(checkPassword(password).mistake);
        self['isWrongPassword'] = true;
        setIsDisabledButton(true);
      } else {
        setMistake(false);
        self['isWrongPassword'] = false;
        setIsDisabledButton(false);
      }
      self['password'] = password;
    };

    const setColor = (color: Colors) => {
      const colorSchema: IColorSchemaModel = colors[color];
      setActiveColorScheme(colorSchema);
      setActiveColor(color);
    };

    const getSettings = flow(function* (token: string) {
      try {
        const json = yield requests.getSetting(token);

        setActiveColor(json.data.color);
        setColor(json.data.color);
        setActiveLanguage(json.data.lang);

        if (json.status === 200) {
          return { status: 200, data: json.data };
        } else if (json.status === 500) {
          setColor(Colors.BLUE);
          setActiveLanguage(Languages.EN);
          return { status: 500, data: [] };
        }
        return true;
      } catch (err) {
        console.error(err);
        setColor(Colors.BLUE);
        setActiveLanguage(Languages.EN);
        return false;
      }
    });

    const setSettings = flow(function* (
      token: string,
      lang: string,
      color: string,
    ) {
      try {
        const json = yield requests.setSetting(token, lang, color);
        return { status: 200, data: json.data };
      } catch (err) {
        console.error('err', err);
        return false;
      }
    });

    const fetchAllTablesRows = flow(function* (
      token: string,
      dbTablesNames: string[],
    ) {
      try {
        const json = yield requests.getAllTablesRows(token, dbTablesNames);

        const countJson = yield requests.getAllTablesCounters(
          token,
          dbTablesNames,
        );
        setTables(
          json.data.map((item: ITableSchema) => {
            item['count'] = countJson.data[item['tableName']];
            return item;
          }),
        );
        return { status: 200, data: json.data };
      } catch (err) {
        return err;
      }
    });

    const fetchTablesList = flow(function* (token: string) {
      try {
        const json = yield requests.getTablesList(token, 'a0881559_affiliate');

        fetchAllTablesRows(token, json.data);
        return { status: 200, data: json.data };
      } catch (err) {
        console.error('err', err);
        return false;
      }
    });

    const fetchTableContent = flow(function* (
      token: string,
      dbTableName: string,
    ) {
      setIsLoading(true);
      try {
        const table = yield requests.getTableContent(token, dbTableName);

        if (
          dbTableName === 'articles' ||
          dbTableName === 'museums' ||
          dbTableName === 'slides'
        ) {
          const authors = yield requests.getTableContent(token, 'authors');
          if (authors) {
            setAuthors(authors.data);
          }
          const manifestType = yield requests.getTableContent(
            token,
            'manifestType',
          );
          if (manifestType) {
            setManifestType(manifestType.data);
          }
          const cities = yield requests.getTableContent(token, 'city');
          if (cities) {
            setCities(cities.data);
          }
        }

        const partners = yield requests.getTableContent(token, 'partners');
        if (partners) {
          setPartners(partners.data);
        }
        const leads = yield requests.getTableContent(token, 'getLeads');
        if (leads) {
          setLeads(leads.data);
        }
        const expenses = yield requests.getTableContent(token, 'expenses');
        if (expenses) {
          setExpenses(expenses.data);
        }
        const services = yield requests.getTableContent(token, 'services');
        if (services) {
          setServices(services.data);
        }
        if (dbTableName === 'pages' || dbTableName === 'articles') {
          const categories = yield requests.getTableContent(
            token,
            'categories',
          );
          if (categories) {
            setCategories(categories.data);
          }
          const tags = yield requests.getTableContent(token, 'tags');
          if (tags) {
            setTags(tags.data);
          }
        }

        if (dbTableName === 'authors') {
          const groups = yield requests.getTableContent(token, 'groups');
          if (groups) {
            setGroups(groups.data);
          }
        }

        if (dbTableName === 'dictionary') {
          const dictionary = yield requests.getTableContent(
            token,
            'dictionary',
          );
          if (dictionary) {
            setDictionary(dictionary.data);
          }
        }
        table.data &&
          table.data?.map((item: any, index: number) => {
            item.key = `${index + 1}`;
            ['hashed_password', 'salt', 'token'].forEach(
              (el) => delete item[el],
            );

            item.content = item.content ? decodeURIComponent(item.content) : '';

            item.status = getStatus(item.status);
            item.isTimerShow = getStatus(item.isTimerShow);
            item.isFormShow = getStatus(item.isFormShow);
            item.isPriceShow = getStatus(item.isPriceShow);
            item.isAdmin = getStatus(item.isAdmin);
            item.tags = item.tags ? item.tags.split(',') : null;
            return item as ITableSchema['rows'];
          });

        setActiveTableArray(table.data);
        setIsLoading(false);
        return { status: 200, data: table.data };
      } catch (err) {
        console.error('err', err);
        setIsLoading(false);
        return false;
      }
    });

    const sendImage = flow(function* (
      token: string,
      file: any,
      imageUrl: string,
      dbTableName: string,
      itemId: string,
    ) {
      setImageUpLoading(true);
      try {
        const json = yield requests.postImage(
          token,
          file,
          imageUrl,
          dbTableName,
          itemId,
        );
        return {
          status: json.status,
          data: json.data.imageUrl,
        } as ResponseProps;
      } catch (err) {
        console.error(err);
        return false;
      }
    });

    const sendImages = flow(function* (
      token: string,
      file: any,
      imageUrl: string,
      dbTableName: string,
      itemId: string,
      imageTitle: string,
      key: number,
      gallery: string,
    ) {
      setImageUpLoading(true);
      try {
        const json = yield requests.postImages(
          token,
          file,
          imageUrl,
          dbTableName,
          itemId,
          imageTitle,
          key,
          gallery,
        );

        return {
          status: json.status,
          data: json.data.imageUrl,
        } as ResponseProps;
      } catch (err) {
        console.error(err);
        return false;
      }
    });

    const deleteImage = flow(function* (
      token: string,
      dbTableName: string,
      itemId: string,
      imageUrl: string,
    ) {
      setImageUpLoading(true);
      try {
        const json = yield requests.deleteImage(
          token,
          dbTableName,
          itemId,
          imageUrl,
        );
        return {
          status: json.status,
        } as ResponseProps;
      } catch (err) {
        console.error(err);
        return false;
      }
    });

    const deleteImages = flow(function* (
      token: string,
      dbTableName: string,
      itemId: string,
      imageUrl: string,
      gallery: string,
    ) {
      setImageUpLoading(true);
      try {
        const json = yield requests.deleteImages(
          token,
          dbTableName,
          itemId,
          imageUrl,
          gallery,
        );
        return {
          status: json.status,
        } as ResponseProps;
      } catch (err) {
        console.error(err);
        return false;
      }
    });

    const updateContent = flow(function* (
      token: string,
      dbTableName: string,
      id: number,
      formData: any,
    ) {
      try {
        const json = yield requests.updateContent(
          token,
          dbTableName,
          id,
          formData,
        );

        return {
          status: json && json.status ? json.status : 200,
          id: id,
        } as unknown as ResponseProps;
      } catch (err) {
        console.error(err);
        return false;
      }
    });

    const createContent = flow(function* (
      token: string,
      dbTableName: string,
      formData: any,
    ) {
      try {
        const json = yield requests.createContent(token, dbTableName, formData);
        return {
          status: json && json.status ? json.status : 200,
          id: json && json.data.id,
        } as ResponseProps;
      } catch (err) {
        console.error(err);
        return false;
      }
    });
    const getAllLeads = flow(function* (token: string) {
      try {
        const leads = yield requests.getTableContent(token, 'getLeads');
        if (leads) {
          setLeads(leads.data);
        }
        return { status: leads.status, data: leads.data };
      } catch (err) {
        console.error(err);
        return false;
      }
    });

    const base64Decode = (text: string) => {
      return window.btoa(encodeURIComponent(text));
    };

    const base64Encode = (text: string) => {
      return decodeURIComponent(window.atob(text));
    };

    return {
      setActiveLanguage,
      t,
      setColor,
      setActiveColor,
      dateFormat,
      numberFormat,
      setScreenWidth,
      setIsAdaptive,
      setActiveBg,
      setActiveStep,
      setSuccessStep,
      setIsFail,
      setEmail,
      setPassword,
      setConfirmPassword,
      setFiledType,
      setCodeWord,
      setConfirmCodeWord,
      setWalletName,
      setMistake,
      setMistakeText,
      setShowPassword,
      getSettings,
      setSettings,
      setActiveColorScheme,
      setIsDisabledButton,
      setIsWrongEmail,
      setSelectedTab,
      setNickName,
      setIsLoading,
      setIsOpenMenu,
      setTables,
      setActiveItem,
      setActiveMenu,
      setPageTitle,
      setActiveCategoryTitle,
      rusDateFormat,
      setActionType,
      setActionId,
      setConfirmAction,
      setOpenModal,
      fetchTablesList,
      fetchTableContent,
      setSent,
      sendImage,
      sendImages,
      setCategories,
      setAuthors,
      setGroups,
      setAnalysis,
      setTags,
      updateContent,
      fetchAllTablesRows,
      setActiveParentMenu,
      setActiveTable,
      createContent,
      setActiveTableArray,
      dateBaseDateFormat,
      base64Decode,
      base64Encode,
      setDelete,
      deleteImage,
      deleteImages,
      setManifestType,
      setPartners,
      getAllLeads,
      setExpenses,
    };
  });

export type ISettingsStore = Instance<typeof SettingsStore>;

export default SettingsStore;
