import s from './expenses-charts.module.scss';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../settings/stores/use-store';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';

export const ExpensesChart = () => {
  const {
    settingsStore: { getExpenses },
  } = useStore();

  const options = {
    chart: {
      type: 'column',
      height: 250,
    },
    title: {
      text: 'Расходы - Доходы',
      align: 'left',
    },
    xAxis: {
      categories: ['Расходы', 'Доходы'],
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Потрачено',
      },
      stackLabels: {
        enabled: true,
      },
    },

    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: getExpenses,
  };

  return (
    <div className={s.expensesChart} style={{ height: 300, width: '50%' }}>
      {getExpenses && (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default observer(ExpensesChart);
