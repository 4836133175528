import cn from 'classnames';
import s from './sub-menu-item.module.scss';
import { CustomButton } from '../../../ui/custom-button';
import { ITableSchema } from '../../../../settings/stores/settings';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../settings/stores/use-store';
import { useNavigate } from 'react-router-dom';
interface MenuItemProps {
  name: ITableSchema['tableName'];
  title: ITableSchema['tableName'];
}
const SubMenuItem = ({ name, title }: MenuItemProps) => {
  const {
    settingsStore: {
      setActiveItem,
      t,
      getActiveItem,
      setActiveCategoryTitle,
      setPageTitle,
    },
  } = useStore();
  const navigate = useNavigate();
  return (
    <li
      className={cn(s.subMenuItem, { [s.isActive]: getActiveItem === name })}
      key={name}
    >
      <CustomButton
        className={cn(
          s.button,
          { [s.isActive]: getActiveItem === name },
          'capitalize',
        )}
        text={t(name)}
        onClick={() => {
          setActiveItem(name);
          setPageTitle(name);
          setActiveCategoryTitle(title);
          navigate(`/tables/${name}`);
        }}
        type="link"
      />
    </li>
  );
};
export default observer(SubMenuItem);
