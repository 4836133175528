import { observer } from 'mobx-react-lite';
import { withRouter } from '../../settings/utils/withRouter';
import LoginBlock from '../../components/login-block';
import {
  ActionTypes,
  FormProps,
  FormType,
  FormTypeInput,
} from '../../settings/utils/types';
import {
  LockOutlined,
  ScheduleOutlined,
  UnlockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useStore } from '../../settings/stores/use-store';

const Register = () => {
  const {
    settingsStore: {
      t,
      getEmail,
      setEmail,
      getPassword,
      setPassword,
      confirmPassword,
      setConfirmPassword,
      getNickName,
      setNickName,
    },
  } = useStore();

  const formItems: FormProps[] = [
    {
      name: 'nickname',
      value: getNickName as string,
      setValue: setNickName,
      widthIcon: true,
      icon: <UserOutlined />,
      placeholder: 'nickname',
      type: FormTypeInput.TEXT,
    },
    {
      name: 'email',
      value: getEmail as string,
      setValue: setEmail,
      widthIcon: true,
      icon: <ScheduleOutlined />,
      placeholder: 'email',
      type: FormTypeInput.TEXT,
    },
    {
      name: 'password',
      value: getPassword as string,
      setValue: setPassword,
      widthIcon: true,
      icon: <UnlockOutlined />,
      placeholder: 'password',
      type: FormTypeInput.PASSWORD,
    },
    {
      name: 'confirmPassword',
      value: confirmPassword as string,
      setValue: setConfirmPassword,
      widthIcon: true,
      icon: <LockOutlined />,
      placeholder: 'confirm password',
      type: FormTypeInput.PASSWORD,
    },
  ];
  return <LoginBlock actionType={ActionTypes.REGISTER} formItems={formItems} />;
};

export default withRouter(observer(Register));
