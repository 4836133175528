import { Methods } from '../utils/types';
import axios from 'axios';
const qs = require('qs');

interface RequestProps {
  route: string;
  method?: Methods;
  body?:
    | {
        [key: string]:
          | string
          | number
          | null
          | { [key: string]: string | number | null }
          | string[];
      }
    | FormData;
  token?: string;
  type?: string;
  isForm?: boolean;
  isImage?: boolean;
}

export const apiRoute = '';

const activeBackend =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BACKEND_HOST
    : process.env.REACT_APP_BACKEND_HOST_ORIGINAL;

const requestAxios = ({
  route,
  isForm,
  method = Methods.GET,
  body = undefined,
  token = '',
  type = 'application/json',
}: RequestProps) => {
  return axios({
    method: method,
    url: `${activeBackend}${apiRoute}${route}`,
    responseType: 'stream',
    data: qs.stringify(body),
  })
    .then(function (response) {
      return { status: response.status, data: JSON.parse(response.data) };
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
};

const requestAxiosFile = async ({ route, body = undefined }: RequestProps) => {
  return await axios
    .postForm(
      // `${process.env.REACT_APP_BACKEND_LOCAL_HOST}${apiRoute}${route}`,
      `${activeBackend}${apiRoute}${route}`,
      {
        file: body,
      },
    )
    .then(function (response) {
      return { status: response.status, data: response.data };
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
};

export const requests = {
  signInUser: async (
    email: string,
    password: string,
    secret = process.env.REACT_APP_TOKEN as string,
  ) =>
    requestAxios({
      route: '/signin',
      method: Methods.POST,
      body: { email, password, secret },
    }),

  registerUser: async (
    nickName: string,
    email: string,
    password: string,
    secret: string,
  ) =>
    requestAxios({
      route: '/signup',
      method: Methods.POST,
      body: {
        nickName,
        email,
        password,
        secret,
      },
    }),
  getCheckAuth: async (secret: string) =>
    requestAxios({
      route: '/check-auth',
      method: Methods.POST,
      body: { secret },
    }),
  getTablesList: async (secret: string, dbTableName: string) =>
    requestAxios({
      route: '/tables-list',
      method: Methods.POST,
      body: { secret, dbTableName },
    }),
  getAllTablesRows: async (secret: string, dbTablesNames: string[]) =>
    requestAxios({
      route: '/check-all-db-tables',
      method: Methods.POST,
      body: { secret, allTables: dbTablesNames.join(',') },
    }),
  getTableContent: async (secret: string, dbTableName: string) =>
    requestAxios({
      route:
        dbTableName !== 'users'
          ? '/get-table-content'
          : '/get-table-content-user',
      method: Methods.POST,
      body: { secret, dbTableName },
    }),
  getAllTablesCounters: async (secret: string, dbTablesNames: string[]) =>
    requestAxios({
      route: '/get-all-content-counters',
      method: Methods.POST,
      body: { secret, allTables: dbTablesNames.join(',') },
    }),
  postImage: async (
    secret: string,
    file: string,
    imageUrl: string,
    dbTableName: string,
    itemId: string,
  ) =>
    requestAxiosFile({
      route: '/image-upload',
      method: Methods.POSTFORM,
      isForm: true,
      body: { secret, file, imageUrl, dbTableName, itemId },
    }),
  postImages: async (
    secret: string,
    file: string,
    imageUrl: string,
    dbTableName: string,
    itemId: string,
    imageTitle: string,
    key: number,
    gallery: string,
  ) =>
    requestAxiosFile({
      route: '/images-upload',
      method: Methods.POSTFORM,
      isForm: true,
      body: {
        secret,
        file,
        imageUrl,
        dbTableName,
        itemId,
        imageTitle,
        key,
        gallery,
      },
    }),
  deleteImage: async (
    secret: string,
    dbTableName: string,
    itemId: string,
    imageUrl: string,
  ) =>
    requestAxios({
      route: '/image-delete',
      method: Methods.POST,
      body: { secret, dbTableName, itemId, imageUrl },
    }),
  deleteImages: async (
    secret: string,
    dbTableName: string,
    itemId: string,
    imageUrl: string,
    gallery: string,
  ) =>
    requestAxios({
      route: '/images-delete',
      method: Methods.POST,
      body: { secret, dbTableName, itemId, imageUrl, gallery },
    }),
  delete: async (secret: string, dbTableName: string, id: number) =>
    requestAxios({
      route: '/delete',
      method: Methods.POST,
      body: { secret, dbTableName, id },
    }),
  updateContent: async (
    token: string,
    dbTableName: string,
    id: number,
    formData: any,
  ) =>
    requestAxios({
      route: '/update-content',
      method: Methods.POST,
      body: { secret: token, dbTableName, id, ...formData },
    }),
  createContent: async (token: string, dbTableName: string, formData: any) =>
    requestAxios({
      route: '/create-content',
      method: Methods.POST,
      body: { secret: token, dbTableName, ...formData },
    }),

  //TODO: переработать

  recover: async (email: string, secret: string) =>
    requestAxios({
      route: '/auth/recover',
      method: Methods.POST,
      body: { email, secret },
    }),
  setSetting: async (token: string, lang: string, color: string) =>
    requestAxios({
      route: '/settings',
      method: Methods.POST,
      token: token,
      body: { lang, color },
    }),
  getSetting: async (token: string) =>
    requestAxios({
      route: '/settings',
      method: Methods.GET,
      token: token,
    }),
  getStat: async (token: string) =>
    requestAxios({
      route: '/get-stat',
      method: Methods.GET,
      token: token,
    }),
};
