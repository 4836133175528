import s from '../../styles/pages/content.module.scss';
import {
  IAllTablesSchema,
  IAuthorsSchema,
  ICitiesTypeSchema,
  IManifestTypeSchema,
  IPartnersSchema,
  IServicesSchema,
  ITagsSchema,
} from '../../settings/stores/settings';
import {
  FormType,
  ICurrencyProps,
  IStatusProps,
  SetValueProps,
  statusOptions,
} from '../../settings/utils/types';
import CustomSelect from '../login-form/custom-select';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { InputText } from '../ui/input-text';
import cn from 'classnames';
import { CustomButton } from '../ui/custom-button';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../settings/stores/use-store';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import locale from 'antd/locale/ru_RU';

import 'suneditor/dist/css/suneditor.min.css';

const ContentForm = () => {
  const { id, action, uid } = useParams();
  const navigate = useNavigate();

  const {
    authStore: { getToken },
    settingsStore: {
      t,
      getTables,
      setSent,
      getSent,
      getCategories,
      getAuthors,
      getAnalysis,
      getPartners,
      getServices,
      getGroups,
      getManifestType,
      getCities,
      getExcludedFields,
      getTags,
      getCurrency,
      getActiveTableItem,
      fetchTableContent,
      setActiveTable,
      updateContent,
      createContent,
      dateBaseDateFormat,
    },
  } = useStore();

  const [newValue, setValue] = useState<SetValueProps>({});
  const [request, setRequest] = useState<IAllTablesSchema | null>(null);

  const selectedTableRows: string[] = getTables
    ?.find(({ tableName }) => tableName === id)
    ?.fields?.filter(
      (item: any) => !getExcludedFields.includes(item),
    ) as string[];

  const [textOriginal, setTextOriginal] = useState<string>(
    getActiveTableItem ? (getActiveTableItem['original'] as string) : '',
  );
  useEffect(() => {
    setValue({ original: textOriginal });
  }, [textOriginal]);

  const [textContent, setTextContent] = useState<string>(
    getActiveTableItem ? (getActiveTableItem['content'] as string) : '',
  );
  const [description, setDescription] = useState<string>(
    getActiveTableItem ? (getActiveTableItem['description'] as string) : '',
  );

  useEffect(() => {
    setValue({ content: textContent });
  }, [textContent]);

  useEffect(() => {
    if (action === FormType.edit) {
      if (!getActiveTableItem && uid) {
        fetchTableContent(getToken as string, id as string).then((res) => {
          if (res && res.status === 200) {
            setActiveTable(+uid as number);
          }
        });
      }

      if (getActiveTableItem) {
        const initialRequest = {
          updatedAt: dateBaseDateFormat(dayjs().toString()),
        };
        for (const key in getActiveTableItem) {
          if (selectedTableRows?.includes(key)) {
            Object.assign(initialRequest, {
              [key]:
                action === FormType.edit
                  ? (getActiveTableItem[key] as unknown as IAllTablesSchema)
                  : null,
            });
          }
        }
        setRequest(initialRequest as unknown as IAllTablesSchema);
      }
    }
  }, [getActiveTableItem, action, uid]);

  useEffect(() => {
    if (action === FormType.create && getTables && selectedTableRows) {
      const initialRequest = {
        createdAt: dateBaseDateFormat(dayjs().toString()),
        status: 0,
      };
      selectedTableRows.forEach((item) => {
        Object.assign(initialRequest, {
          [item]: null,
        });
      });
      setRequest(initialRequest as unknown as IAllTablesSchema);
    }
  }, [getTables, action, uid]);

  useEffect(() => {
    if (newValue && selectedTableRows) {
      for (const key in newValue) {
        //    @ts-ignore
        request[key] = newValue[key];
        setSent(false);
      }
    }
  }, [newValue]);

  useEffect(() => {
    if (getSent && getToken && uid && request) {
      console.log('request', request);
      if (request['tags']) {
        const requestTag: string[] = request['tags'] as string[];
        request['tags'] = requestTag?.join(',');
      }
      if (request['author']) {
        const requestAuthor: string[] = request['author'] as string[];
        request['author'] = Array.isArray(requestAuthor)
          ? requestAuthor?.join(',')
          : requestAuthor;
      }

      // if (request['groups']) {
      //   const requestGroups: string[] = request['groups'] as string[];
      //   request['groups'] = requestGroups?.join(',');
      // }

      if (action === FormType.edit) {
        updateContent(getToken, id as string, +uid, request).then((res) => {
          console.log('res', res);
          if (res && res.status === 200) {
            navigate(`/tables/${id}`);
          }
        });
      } else if (action === FormType.create) {
        createContent(getToken, id as string, request).then((res) => {
          if (res && res.status === 200) {
            navigate(`/tables/${id}`);
          }
        });
      }

      setSent(false);
    }
  }, [getSent]);

  const OwnSelect = (
    item: string,
    dataTable:
      | IAllTablesSchema[]
      | IStatusProps[]
      | ITagsSchema[]
      | IManifestTypeSchema[]
      | IAuthorsSchema[]
      | IPartnersSchema[]
      | IServicesSchema[]
      | ICurrencyProps[]
      | ICitiesTypeSchema[],
    multiple?: boolean,
  ) => {
    if (multiple) {
      dataTable = dataTable.map((el: any) => ({
        ...el,
        value: el.id,
        label: el.title || el.value,
      })) as
        | IAllTablesSchema[]
        | IStatusProps[]
        | ITagsSchema[]
        | IManifestTypeSchema[]
        | ICitiesTypeSchema[]
        | IPartnersSchema[]
        | IServicesSchema[]
        | ICurrencyProps[]
        | IAuthorsSchema[];
    }

    const targetArray: string[] | number[] =
      getActiveTableItem &&
      ['tags', 'author'].includes(item) &&
      getActiveTableItem[item] != null
        ? Array.isArray(getActiveTableItem[item])
          ? (getActiveTableItem[item] as unknown as string[] | number[])
          : (`${getActiveTableItem[item]}`.split(',') as unknown as
              | string[]
              | number[])
        : [];

    return (
      <div className={s.formItem}>
        <CustomSelect
          dataTable={dataTable}
          item={item}
          defaultValue={
            multiple && getActiveTableItem && getActiveTableItem[item]
              ? targetArray.map((el) => +el)
              : getActiveTableItem
                ? t(getActiveTableItem[item] as string)
                : ''
          }
          setValue={setValue}
          multiple={multiple as boolean}
        />
      </div>
    );
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, true] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  return (
    <div className={s.form}>
      <div className={s.formItems}>
        {getTables &&
          selectedTableRows?.map((item: string) => {
            let formItem;
            switch (item) {
              case 'content':
              case 'original':
                formItem = (
                  <ReactQuill
                    theme="snow"
                    defaultValue={
                      getActiveTableItem && item === 'content'
                        ? (getActiveTableItem['content'] as string)
                        : item === 'original'
                          ? getActiveTableItem &&
                            (getActiveTableItem['original'] as string)
                          : ''
                    }
                    value={item === 'content' ? textContent : textOriginal}
                    onChange={
                      item === 'content' ? setTextContent : setTextOriginal
                    }
                    modules={modules}
                    formats={formats}
                  />
                );
                break;
              case 'description':
                formItem = (
                  <ReactQuill
                    theme="snow"
                    defaultValue={
                      getActiveTableItem && item === 'description'
                        ? (getActiveTableItem['description'] as string)
                        : ''
                    }
                    value={description}
                    onChange={setDescription}
                    modules={modules}
                    formats={formats}
                  />
                );
                break;
              case 'originalyear':
                formItem = (
                  <DatePicker
                    defaultValue={
                      getActiveTableItem
                        ? (dayjs(
                            getActiveTableItem.originalyear,
                            'YYYY',
                          ) as Dayjs)
                        : undefined
                    }
                    onChange={(e: any) =>
                      setValue({
                        [item]: e.format('YYYY') as unknown as string,
                      })
                    }
                    picker="year"
                  />
                );
                break;
              case 'publishDate':
                formItem = (
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      format="DD.MM.YYYY"
                      defaultValue={
                        getActiveTableItem
                          ? (dayjs(
                              getActiveTableItem.publishDate,
                              'DD.MM.YYYY',
                            ) as Dayjs)
                          : undefined
                      }
                      onChange={(e: any) =>
                        setValue({
                          [item]: e.format('YYYY-MM-DD') as unknown as string,
                        })
                      }
                    />
                  </ConfigProvider>
                );
                break;
              case 'author':
                formItem = OwnSelect(item, getAuthors, true);
                break;
              case 'partner':
                formItem = OwnSelect(item, getPartners as IPartnersSchema[]);
                break;
              case 'service':
                formItem = OwnSelect(item, getServices as IServicesSchema[]);
                break;
              case 'currency':
                formItem = OwnSelect(item, getCurrency as ICurrencyProps[]);
                break;
              case 'status':
                formItem = OwnSelect(item, statusOptions);
                break;
              case 'isFormShow':
                formItem = OwnSelect(item, statusOptions);
                break;
              case 'isTimerShow':
                formItem = OwnSelect(item, statusOptions);
                break;
              case 'isPriceShow':
                formItem = OwnSelect(item, statusOptions);
                break;
              case 'isAdmin':
                formItem = OwnSelect(item, statusOptions);
                break;
              case 'analysis':
                formItem = OwnSelect(item, getAnalysis);
                break;
              case 'category':
                formItem = OwnSelect(item, getCategories);
                break;
              case 'tags':
                formItem = OwnSelect(item, getTags, true);
                break;
              case 'groups':
                formItem = OwnSelect(item, getGroups, true);
                break;
              case 'manifestType':
                formItem = OwnSelect(item, getManifestType);
                break;
              case 'city':
                formItem = OwnSelect(item, getCities);
                break;
              default:
                formItem = (
                  <div className={s.formItem}>
                    <InputText
                      setValue={(e) => setValue({ [item]: e })}
                      name={item}
                      defaultValue={
                        getActiveTableItem && getActiveTableItem[item]
                          ? (getActiveTableItem[item] as unknown as string)
                          : ''
                      }
                    />
                  </div>
                );
                break;
            }
            return (
              <div key={item} className={cn(s.formItemGroup, item)}>
                <label htmlFor={item}>{item}</label>
                {formItem}
              </div>
            );
          })}
      </div>
      <CustomButton
        type="primary"
        onClick={() => {
          setSent(true);
        }}
        text="Сохранить"
      />
    </div>
  );
};

export default observer(ContentForm);
