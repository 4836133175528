import { Button, Tooltip } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SvgIcons } from '../../svg-icons';
import { Positions } from '../../../settings/utils/types';
import React, { CSSProperties } from 'react';
import { ButtonShape, ButtonType } from 'antd/lib/button';

interface CustomButtonProps {
  size?: SizeType;
  type?: ButtonType;
  text?: string;
  className?: string;
  onClick: () => void;
  withIcon?: boolean;
  isDisabled?: boolean;
  icon?: string;
  withTooltip?: boolean;
  tooltip?: string;
  fill?: string;
  shape?: ButtonShape;
  isLoading?: boolean;
  image?: JSX.Element;
  withImage?: boolean;
  imagePosition?: Positions;
  symbol?: string;
  danger?: boolean;
  withSymbol?: boolean;
  symbolPosition?: Positions;
  style?: CSSProperties;
  setRef?: React.Ref<HTMLElement>;
  children?: JSX.Element;
}
export const CustomButton = ({
  text,
  onClick,
  className,
  withIcon,
  icon,
  isDisabled,
  shape,
  fill,
  type = 'text',
  size = 'large',
  image,
  withImage,
  imagePosition,
  symbol,
  withSymbol,
  symbolPosition,
  style,
  children,
  danger,
  tooltip,
  withTooltip,
}: CustomButtonProps) => {
  return (
    <Tooltip title={withTooltip ? tooltip : undefined}>
      <Button
        danger={danger as boolean}
        shape={shape as ButtonShape}
        disabled={isDisabled as boolean}
        size={size}
        type={type as ButtonType}
        style={style as CSSProperties}
        onClick={onClick}
        className={className as string}
        icon={
          withIcon ? (
            <SvgIcons icon={icon as string} fill={fill as string} />
          ) : undefined
        }
      >
        {children ? children : undefined}
        {withSymbol && symbolPosition === Positions.LEFT
          ? `${symbol}${' '}`
          : undefined}
        {withImage && imagePosition === Positions.LEFT && image
          ? image
          : undefined}
        {text ? text : undefined}
        {withImage && imagePosition === Positions.RIGHT && image
          ? image
          : undefined}
        {withSymbol && symbolPosition === Positions.RIGHT
          ? `${' '}${symbol}`
          : undefined}
      </Button>
    </Tooltip>
  );
};
