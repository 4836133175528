import { withRouter } from '../../settings/utils/withRouter';
import { observer } from 'mobx-react-lite';
import ContentForm from '../../components/content-form';
import { useStore } from '../../settings/stores/use-store';
import { FormType } from '../../settings/utils/types';
import { useParams } from 'react-router-dom';

const TableAction = () => {
  const {
    settingsStore: { t },
  } = useStore();
  const { action } = useParams();
  return (
    <div className="content">
      <h2>{t(action as string)}</h2>
      <ContentForm />
    </div>
  );
};

export default withRouter(observer(TableAction));
