import { observer } from 'mobx-react-lite';
import s from './item-counts.module.scss';
import { useStore } from '../../settings/stores/use-store';
import { ITableSchema } from '../../settings/stores/settings';
import { useNavigate } from 'react-router-dom';

interface ItemCountProps {
  tableName: ITableSchema['tableName'];
  count: ITableSchema['count'];
}
const ItemCounts = ({ tableName, count }: ItemCountProps) => {
  const {
    settingsStore: { t, numberFormat },
  } = useStore();
  const navigate = useNavigate();

  return (
    <div
      className={s.itemCount}
      onClick={() => navigate(`/tables/${tableName}`)}
    >
      <div className={s.titleBlock}>
        <span className={s.title}>{t(tableName as string)}</span>
      </div>
      {count && (
        <div className={s.countBlock}>
          <span className={s.count}>
            {typeof count === 'number' ? numberFormat(count) : count}
          </span>
        </div>
      )}
    </div>
  );
};

export default observer(ItemCounts);
