import { ReactNode } from 'react';
import { ITableSchema } from '../stores/settings';

export enum Methods {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POSTFORM = 'postForm',
}

export enum FieldType {
  password = 'password',
  text = 'text',
}
export type FieldProps = FieldType.password | FieldType.text;

export enum Positions {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export enum Languages {
  EN = 'EN',
  RU = 'RU',
}

export interface LanguagesProp {
  [Languages.RU]: string;
}

export enum Colors {
  BLUE = 'BLUE',
  RED = 'RED',
  GREEN = 'GREEN',
  PURPLE = 'PURPLE',
  BLACK = 'BLACK',
}
export interface ColorsProps {
  BLUE: Colors.BLUE;
  RED: Colors.RED;
  GREEN: Colors.GREEN;
  PURPLE: Colors.PURPLE;
  BLACK: Colors.BLACK;
}
export interface MetaProps {
  title: string;
  description?: string;
  keywords?: string;
  image?: string;
  type?: string;
  url?: string;
}

export interface FormProps {
  name: string;
  value?: string;
  defaultValue?: string;
  setValue: (value: string) => void;
  widthIcon: boolean;
  icon: ReactNode;
  placeholder: string;
  type: FormTypeInput;
}

export enum FormTypeInput {
  TEXT = 'text',
  PASSWORD = 'password',
}

export enum FormType {
  create = 'create',
  edit = 'edit',
}

export type PropsType = string | number | boolean;

export interface KeyValueProps {
  [key: string]: PropsType;
}

export interface DashboardCountersProps {
  name: string;
  title?: string;
  onChange: Function;
  count: number;
}

export enum StatusSign {
  Active = 'Active',
  Disabled = 'Disabled',
}

export enum Status {
  Active = 1,
  Disabled = 0,
}

export const getStatus = (key: number) => Status[key];

export interface IStatusProps {
  key: Status | string | CurrencyType;
  value: string | number;
  id: number;
}

export const statusOptions: IStatusProps[] = [
  {
    key: Status[0] as string,
    value: '0',
    id: 0,
  },
  {
    key: Status[1] as string,
    value: '1',
    id: 1,
  },
];
export enum Token {
  id = 'sid',
}

export enum Position {
  left = 1,
  right = 2,
}

export enum ActionTypes {
  DELETE = 'delete',
  EDIT = 'edit',
  LOGIN = 'login',
  REGISTER = 'register',
}

export interface ResponseProps {
  data?: number | string;
  id?: number | string;
  message: string | null;
  status: number;
}

export interface SetValueProps {
  [key: string]: string | number | string[] | number[] | (string | number)[];
}

export interface RoutesProps {
  id: number;
  path: string;
  component: React.ReactNode;
  mainRoutes: boolean;
  isActive: boolean;
  isAdmin: boolean;
}
export type PageTypeProps = PageType.login | PageType.signup;

export enum PageType {
  login = 'login',
  signup = 'signup',
}
export interface MenuItemsValuesProps {
  name: string;
  title: string;
  items?: ITableSchema['tableName'][];
  icon: string;
  onClick: Function;
}

export enum ManifestType {
  SIMVOLIZM = 'Символизм',
  AKMEIZM = 'Акмеизм',
  FUTURIZM = 'Футуризм',
}

export enum CurrencyEnum {
  RUB = 'RUB',
  EUR = 'EUR',
  USD = 'USD',
}

export type CurrencyType =
  | CurrencyEnum.RUB
  | CurrencyEnum.EUR
  | CurrencyEnum.USD;

export interface ICurrencyProps {
  key: CurrencyType;
  value: number;
  id: number;
}
