import cn from 'classnames';
import s from './user-info.module.scss';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../settings/stores/use-store';

const UserInfo = () => {
  const {
    authStore: { getUser, getIsLoading },
  } = useStore();
  return (
    <li className={cn(s.user)}>
      <Avatar size={64} icon={<UserOutlined rev="" />} />
      {!getIsLoading && getUser && (
        <div className={s.userInfo}>
          <p className={s.userName}>{getUser.username}</p>
          <p className={s.userEmail}>{getUser.email}</p>
        </div>
      )}
    </li>
  );
};
export default observer(UserInfo);
