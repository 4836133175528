import { withRouter } from '../../settings/utils/withRouter';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useStore } from '../../settings/stores/use-store';
import { ActionTypes } from '../../settings/utils/types';
import { requests } from '../../settings/api';
import { Table } from 'antd';
import { toJS } from 'mobx';
import DeletePopUp from '../../components/delete-pop-up';
import ActionCrudBlock from '../../components/action-crud-block';
import TableRowItem from '../../components/table-row-item';
import TableHeaderBlock from '../../components/table-header-block';

import { ColumnsType } from 'antd/es/table';
import { ExpensesChart } from '../../components/charts/expenses-chart';

interface DataType {
  key: React.Key;
  title: string;
  dataIndex: string;
  width?: number;
  fixed?: 'right' | 'left' | undefined;
  render?: () => React.ReactNode;
}

const Tables = () => {
  const { id } = useParams();
  const {
    settingsStore: {
      getActiveTable,
      getActiveItem,
      setActiveItem,
      setActionType,
      getActionId,
      getActionType,
      setConfirmAction,
      getConfirmAction,
      setOpenModal,
      getOpenModal,
      setIsLoading,
      getIsTablesLoading,
      getExcludedTableFields,
      fetchTableContent,
      getActiveTableArray,
    },
    authStore: { getToken },
  } = useStore();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    if (getToken && id) {
      fetchTableContent(getToken, id);
    }
    if (!getActiveItem) {
      setActiveItem(id as string);
    }
  }, [id]);
  useEffect(() => {
    if (getToken && id && reload) {
      fetchTableContent(getToken, id);
      setReload(false);
    }
  }, [reload]);

  useEffect(() => {
    setIsLoading(true);
    if (
      getConfirmAction &&
      getActionType === ActionTypes.DELETE &&
      getActiveTable
    ) {
      requests
        .delete(
          getToken as string,
          getActiveTable[0].tableName as string,
          getActionId as number,
        )
        .then((res) => {
          if (res && res.status === 200) {
            setReload(true);
          }
        });
      setConfirmAction(false);
      setActionType(null);
      setOpenModal(false);
      setIsLoading(false);
    }
  }, [getConfirmAction]);

  console.log('getActiveTable', getActiveTable);
  console.log('getActiveTableArray', getActiveTableArray);
  return (
    <div>
      {!getIsTablesLoading &&
        getActiveTable?.map(({ fields }) => {
          const columns: ColumnsType<DataType> = toJS(fields)
            ?.filter((el) => !getExcludedTableFields?.includes(el))
            .map((el) => {
              let width;
              switch (el) {
                case 'id':
                  width = 60;
                  break;
                case 'partner':
                  width = 150;
                  break;
                case 'status':
                  width = 100;
                  break;
                case 'login':
                  width = 250;
                  break;
                case 'password':
                  width = 250;
                  break;
                case 'service':
                  width = 150;
                  break;
                case 'cost':
                  width = 130;
                  break;
                case 'createdAt':
                case 'updatedAt':
                  width = 110;
                  break;
                default:
                  width = 200;
                  break;
              }
              return {
                title: el,
                dataIndex: el,
                key: el,
                width: width,
                render: (_: any, item: any) => (
                  <TableRowItem el={el} item={item} />
                ),
              };
            }) as ColumnsType<DataType>;
          columns?.push({
            title: 'action',
            key: 'action',
            dataIndex: '',
            fixed: 'right',
            render: (_: any, record: any) => (
              <ActionCrudBlock recordId={record.id} />
            ),
          });
          return (
            <div key={id} className="tableBlock">
              <TableHeaderBlock />
              {getActiveTableArray && (
                <Table
                  scroll={{ x: (columns.length + 1) * 150 }}
                  dataSource={getActiveTableArray as unknown as any}
                  columns={columns}
                  footer={() => (
                    <p>Всего записей: {getActiveTableArray.length}</p>
                  )}
                />
              )}
              {getOpenModal && <DeletePopUp />}
            </div>
          );
        })}
    </div>
  );
};

export default withRouter(observer(Tables));
