import { useStore } from '../../settings/stores/use-store';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import s from '../../components/item-counts/item-counts.module.scss';
import { withRouter } from '../../settings/utils/withRouter';
import ItemCounts from '../../components/item-counts';
import { ITableSchema } from '../../settings/stores/settings';
import { Charts } from '../../components/charts';

const Dashboard = () => {
  const {
    settingsStore: {
      t,
      setActiveItem,
      setActiveCategoryTitle,
      getActiveCategoryTitle,
      getTables,
    },
  } = useStore();

  useEffect(() => {
    if (!getActiveCategoryTitle) {
      setActiveCategoryTitle(t('Dashboard') as string);
      setActiveItem(null);
    }
  }, []);

  return (
    <div className={s.dashboard}>
      <Charts />
      <div className={s.itemCountsBlock}>
        <h2>Контент на сайте</h2>
        <div className={s.itemCounts}>
          {getTables &&
            getTables?.map(({ tableName, count }: ITableSchema) => (
              <ItemCounts key={tableName} count={count} tableName={tableName} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(observer(Dashboard));
