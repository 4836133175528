import s from './form-item.module.scss';
import { InputText } from '../../ui/input-text';
import { FormProps, FormTypeInput } from '../../../settings/utils/types';
import { InputPassword } from '../../ui/input-password';

export const FormItem = ({
  icon,
  value,
  setValue,
  placeholder,
  name,
  widthIcon,
  type,
}: FormProps) => {
  return (
    <div className={s.formItem}>
      {type === FormTypeInput.TEXT && (
        <InputText
          icon={icon}
          widthIcon={widthIcon}
          value={value}
          placeholder={placeholder}
          name={name}
          setValue={setValue}
        />
      )}
      {type === FormTypeInput.PASSWORD && (
        <InputPassword
          placeholder={placeholder}
          widthIcon
          value={value as string}
          setValue={setValue}
          name={name}
        />
      )}
    </div>
  );
};
