import { observer } from 'mobx-react-lite';
import s from './tatle-block.module.scss';
import { useStore } from '../../../settings/stores/use-store';
// import Breadcrumbs from './breadcrumbs';

const TitleBlock = () => {
  const {
    settingsStore: { getActiveCategoryTitle },
  } = useStore();
  return (
    <div className={s.titleBlock}>
      <h1 className={s.title}>{getActiveCategoryTitle}</h1>
      {/*<Breadcrumbs /> //TODO:временно отключил */}
    </div>
  );
};

export default observer(TitleBlock);
