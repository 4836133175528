import s from './copyright.module.scss';

export const Copyright = () => (
  <div className={s.copyRight}>
    <p className={s.text}>
      <a href="https://punktinitiative.ru" className={s.link}>
        <i className="dot">.</i>initiative
      </a>
      &copy; Admin Panel 2008-{new Date().getFullYear()} {' | '} ver.{' '}
      {/*{process.env.REACT_APP_VERSION}*/}
    </p>
  </div>
);
