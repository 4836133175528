import cn from 'classnames';
import s from './content.module.scss';
import HeadNav from '../head-nav';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../settings/stores/use-store';
import TitleBlock from '../title-block';
import { Copyright } from '../../copyright';

const Content = ({ children }: any) => {
  const {
    settingsStore: { getIsOpenMenu },
  } = useStore();
  return (
    <div className={cn(s.content, { [s.isActive]: getIsOpenMenu })}>
      <div className={s.wrap}>
        <HeadNav />
        <TitleBlock />
        {children}
      </div>
      <div className={s.bottomPanel}>
        <Copyright />
      </div>
    </div>
  );
};
export default observer(Content);
