import { flow, Instance, types as t } from 'mobx-state-tree';
import { toJS } from 'mobx';
import { requests } from '../api';

const UserModel = t.model('UserModel', {
  email: t.string,
  id: t.number,
  isAdmin: t.number,
  status: t.number,
  token: t.string,
  username: t.string,
  createdAt: t.maybeNull(t.string),
  updatedAt: t.maybeNull(t.string),
});

export type IUserModel = Instance<typeof UserModel>;

const AuthStore = t
  .model('AuthStore', {
    user: t.maybeNull(UserModel),
    isAuth: t.maybeNull(t.boolean),
    token: t.maybeNull(t.string),
    isLoading: t.boolean,
  })
  .views((self: any) => ({
    get getUser() {
      return toJS(self.user);
    },
    get getIsLoading() {
      return toJS(self.isLoading);
    },
    get getIsAuth() {
      return toJS(self.isAuth);
    },
    get getToken() {
      return toJS(self.token);
    },
    get getAuthPages() {
      const authPages = ['/admin/login', '/admin/repair', '/admin/register'];
      return toJS(authPages);
    },
  }))
  .actions((self: any) => {
    const setIsLoading = (status: boolean) => {
      self.isLoading = status;
    };
    const setUser = (user: IUserModel | null) => {
      self.user = user;
    };
    const setIsAuth = (status: boolean) => {
      self.isAuth = status;
    };
    const setToken = (token: string | null) => {
      self.token = token;
    };

    const userAuth = (token: string, user: IUserModel) => {
      setToken(token);
      setIsAuth(true);
      setUser(user);
      setIsLoading(false);
    };

    const userAuthCancel = () => {
      setToken(null);
      setIsAuth(false);
      setUser(null);
      setIsLoading(false);
    };

    const fetchUser = flow(function* (token: string) {
      setIsLoading(true);
      try {
        const json = yield requests.getCheckAuth(token);
        userAuth(token, json.data);
        return { status: json.status, data: json.data };
      } catch (err) {
        userAuthCancel();
        return err;
      }
    });

    const loginUser = flow(function* (
      email: string,
      password: string,
      secret: string,
    ) {
      setIsLoading(true);
      try {
        const json = yield requests.signInUser(email, password, secret);

        userAuth(secret, json.data);
        return { status: json.status, data: json.data };
      } catch (err) {
        userAuthCancel();
        return err;
      }
    });

    const registerUser = flow(function* (
      nickName: string,
      email: string,
      password: string,
      token: string,
    ) {
      setIsLoading(true);
      try {
        const json = yield requests.registerUser(
          nickName,
          email,
          password,
          token,
        );
        window.location.href = '/signin';
        setIsLoading(false);
        return { status: json.status, data: json.data };
      } catch (err) {
        userAuthCancel();
        return err;
      }
    });

    return {
      setUser,
      setIsAuth,
      fetchUser,
      setToken,
      setIsLoading,
      registerUser,
      loginUser,
      userAuthCancel,
    };
  });

export type IAuthStore = Instance<typeof AuthStore>;

export default AuthStore;
