import s from './table-image.module.scss';
import { withRouter } from '../../settings/utils/withRouter';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../settings/stores/use-store';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons';
import ContentImage from '../../components/content-image';
import { useEffect, useRef, useState } from 'react';
import { CustomButton } from '../../components/ui/custom-button';
import { Button } from 'antd';

const TableImage = () => {
  const { id, uid } = useParams();

  const {
    settingsStore: {
      getActiveTableItem,
      t,
      fetchTableContent,
      setActiveTable,
      setSent,
      getSent,
      setDelete,
      getDelete,
      sendImage,
      deleteImage,
    },
    authStore: { getToken },
  } = useStore();

  useEffect(() => {
    if (!getActiveTableItem && uid) {
      fetchTableContent(getToken as string, id as string).then((res) => {
        if (res && res.status === 200) {
          setActiveTable(+uid as number);
        }
      });
    }
  }, [getActiveTableItem, uid]);

  const form = useRef<HTMLFormElement>();
  const fileInput = useRef<HTMLInputElement>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [updateImage, setUpdateImage] = useState<boolean>(false);
  const [newImage, setNewImage] = useState<string | null>();
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    setNewImage(getActiveTableItem?.image as string);
  }, [getActiveTableItem, uid]);

  useEffect(() => {
    const doneAction = function done() {
      setCopied(false);
    };
    const setDone = setTimeout(doneAction, 3000);
    return () => clearTimeout(setDone);
  }, [copied]);

  useEffect(() => {
    if (getToken && getSent && fileInput?.current?.files) {
      sendImage(
        getToken,
        fileInput?.current?.files[0],
        imageUrl,
        id as string,
        uid as string,
      ).then((res) => {
        if (res) {
          setNewImage(res.data as string);
          window.location.reload();
        }
      });
      setSent(false);
    }
  }, [getSent]);
  useEffect(() => {
    if (getToken && getDelete) {
      deleteImage(
        getToken,
        id as string,
        uid as string,
        newImage as string,
      ).then((res) => {
        if (res) {
          window.location.reload();
        }
      });
      setSent(false);
    }
  }, [getDelete]);

  useEffect(() => {
    if (
      updateImage &&
      fileInput &&
      fileInput?.current &&
      fileInput?.current?.files &&
      fileInput?.current?.files.length > 0
    ) {
      setImageUrl(URL.createObjectURL(fileInput?.current?.files[0]));
      setUpdateImage(false);
    }
  }, [updateImage, fileInput, imageUrl, uid]);

  return (
    <div className="content">
      <a href={`/tables/${id}`}>
        <ArrowLeftOutlined rev="" /> Вернуться к таблицам
      </a>
      <div className={s.imagePage}>
        <div className={s.imageBlock}>
          <div className={s.imageWrap}>
            <h2>{t('Image')}</h2>
            {newImage && (
              <ContentImage
                title={id as string}
                url={`${process.env.REACT_APP_BACKEND_HOST_ORIGINAL}/${newImage}`}
              />
            )}
          </div>
          <div className={s.imageWrap}>
            {imageUrl && (
              <>
                <h2>Новое изображение</h2>
                <ContentImage title="New Image" url={imageUrl} />
              </>
            )}
          </div>
        </div>
        <form
          className={s.form}
          ref={(ref: HTMLFormElement) => (form.current = ref)}
        >
          <input
            type="hidden"
            name="secret"
            defaultValue={getToken as string}
          />
          <input
            ref={(input: HTMLInputElement) => (fileInput.current = input)}
            name="file"
            hidden
            type="file"
            onChange={(e) => {
              setUpdateImage(true);
            }}
          />

          <div className={s.formActionsBlock}>
            <CustomButton
              className={s.chooseButton}
              icon="PlusOutlined"
              fill="#FFFFFF"
              withIcon
              type="default"
              onClick={() => {
                fileInput.current?.click();
              }}
              text={t('Choose file')}
            />
            {fileInput.current && imageUrl && (
              <CustomButton
                type="primary"
                danger
                onClick={() => {
                  setSent(false);
                  setImageUrl('');
                  setUpdateImage(false);
                }}
                text={t('Cancel')}
              />
            )}
            <CustomButton
              type="primary"
              onClick={() => setSent(true)}
              text={t('Save')}
            />
            <CustomButton
              danger
              type="primary"
              onClick={() => setDelete(true)}
              text={t('Delete')}
            />
          </div>
        </form>
      </div>
      {newImage && (
        <div className={s.copyPath}>
          <p>Ссылка для копирования картинки</p>{' '}
          <p>
            {`${process.env.REACT_APP_BACKEND_HOST_ORIGINAL}/${newImage}`}{' '}
            <Button
              icon={<CopyOutlined />}
              onClick={() => {
                setCopied(true);
                navigator.clipboard.writeText(
                  `${process.env.REACT_APP_BACKEND_HOST_ORIGINAL}/${newImage}`,
                );
              }}
            />
          </p>
        </div>
      )}
      {copied && <p className={s.copied}>Скопировано!</p>}
    </div>
  );
};

export default withRouter(observer(TableImage));
