import s from './head-nav.module.scss';
import { CustomButton } from '../../ui/custom-button';
import { observer } from 'mobx-react-lite';
import { Token } from '../../../settings/utils/types';
import { useStore } from '../../../settings/stores/use-store';
import { useLocation } from 'react-router-dom';
import { deleteCookie } from 'cookies-next';

const HeadNav = () => {
  const router = useLocation();
  const {
    settingsStore: { getIsOpenMenu, setIsOpenMenu, t },
    authStore: { setIsAuth },
  } = useStore();
  return (
    <div className={s.headNav}>
      <div className={s.leftBlock}>
        <CustomButton
          className={s.navButton}
          withIcon
          icon="MenuOutlined"
          type="primary"
          onClick={() => setIsOpenMenu(!getIsOpenMenu)}
        />
      </div>
      <div className={s.rightBlock}>
        <CustomButton
          className={s.logoutButton}
          withIcon
          icon="LogoutOutlined"
          type="link"
          size="small"
          onClick={() => {
            deleteCookie(Token.id);
            setIsAuth(false);
            // router.push('/admin/login'); //TODO
          }}
          text={t('Logout')}
        />
      </div>
    </div>
  );
};

export default observer(HeadNav);
